import { searchJob } from "services/Collections";
import React, { useEffect, useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import { useSelector } from "react-redux";
import { Loader2 } from "lucide-react";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";
import { useDispatch } from "react-redux";
import { updateHeader } from "store/slices/StmateSlice";
import { styled } from "styled-components";

const Header = ({ jobDetails, header, setHeader }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.shared?.LoginSlice?.data);
  const currentJob = useSelector((state) => state?.tabSpecific?.STMate?.outsideJob);
  const headerData = useSelector((state) => state?.tabSpecific?.STMate?.header);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchJobDetails = async () => {
    if (!currentJob || currentJob == "123") {
      setError("You have to select the job");
      return;
    }
    setLoading(true);
    setError(null);
    setHeader(true);
    try {
      let params = new URLSearchParams();
      params.append("job", currentJob);
      params.append("user_id", currentUser?.id);
      let res = await searchJob(params?.toString());
      if (res && res.data) {
        let obj = {
          job_number: res.data.job.number || "",
          job_type: res.data.job.type || "",
          status: res.data.job.status || "",
          service_titan: res.data.servicetitan_link || "",
          business_unit: res.data.business_unit || "",
          customer: res.data.customer || "",
          campaign: res.data.campaign || "",
          technicians: res.data.technicians?.toString() || "",
        };
        dispatch(updateHeader({ data: obj, currentJob }));
      } else {
        dispatch(updateHeader(null));
        throw new Error("No data received from the server");
      }
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
    } finally {
      setLoading(false);
      setHeader(false);
    }
  };

  useEffect(() => {
    if (!headerData && currentJob !== headerData?.currentJob) {
      fetchJobDetails();
    }
  }, [currentJob]);

  return (
    <HeaderStyled>
      <div
        onClick={() => setHeader(true)}
        className={`w-full bg-[#474747] flex justify-center items-center gap-2 cursor-pointer text-white ${
          header ? "h-0 hidden" : "h-[40px] block"
        }`}
      >
        <MdKeyboardDoubleArrowDown
          style={{ width: "24px", height: "24px" }}
          color="currentColor"
        />
        <span>
          {jobDetails?.job_type} {jobDetails?.customer && "||"}{" "}
          {jobDetails?.customer}
        </span>
      </div>

      <div className={`main-Container ${!header ? "hidden" : "block"}`}>
        <div className="main-inner">
          {loading ? (
            <div className="flex bg-[#474747] justify-center items-center">
              <Loader2 className="h-8 w-8 animate-spin text-white" />
            </div>
          ) : error ? (
            <p style={{ textAlign: "center", background: "#515151" }}>
              {error}
            </p>
          ) : jobDetails ? (
            <div className="box-Wrapper">
              <div className="space-y-0 md:space-y-2">
                {Object?.entries(jobDetails)
                  ?.slice(0, 4)
                  ?.map(([key, value], i) => (
                    <div
                      key={key}
                      className={`flex gap-2 md:justify-between items-center ${
                        i % 2 ? "bg-[#D9D9D9]" : "bg-[#f3f4f6]"
                      } text-black py-1 px-2 md:py-3`}
                    >
                      <span className="capitalize font-bold">
                        {key.replace("_", " ")}:
                      </span>
                      {key == "service_titan" ? (
                        <a
                          className="text-blue-500"
                          href={value}
                          target="_blank"
                        >
                          Link
                        </a>
                      ) : (
                        <span>{value}</span>
                      )}
                    </div>
                  ))}
              </div>
              <div className="space-y-0 md:space-y-2">
                {Object.entries(jobDetails)
                  ?.slice(4)
                  ?.map(([key, value], i) => (
                    <div
                      key={key}
                      className={`flex gap-2 md:justify-between items-center ${
                        i % 2 ? "bg-[#D9D9D9]" : "bg-[#f3f4f6]"
                      } text-black py-1 px-2 md:py-3`}
                    >
                      <span className="capitalize font-bold">
                        {key.replace("_", " ")}:
                      </span>
                      <span>{value}</span>
                    </div>
                  ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div
        onClick={() => setHeader(false)}
        className={`w-full h-[40px] bg-[#474747] flex justify-center items-center gap-2 cursor-pointer text-white ${
          !header ? "h-0 hidden" : "h-[40px] block"
        }`}
      >
        <MdKeyboardDoubleArrowUp
          style={{ width: "24px", height: "24px" }}
          color="currentColor"
        />
        <span>Close</span>
      </div>
    </HeaderStyled>
  );
};

export default Header;

const HeaderStyled = styled.div`
  .main-Container {
    width: 100%;
    background: #515151;
    color: #fff;
    font-weight: 700;
    padding: 0 16px;
    @media (max-width: 767px) {
      padding: 0;
      background: #fff;
    }
  }
  .main-inner {
    padding: 21px 81px;
    @media (max-width: 835px) {
      padding: 21px;
    }
    @media (max-width: 767px) {
      padding: 0px;
    }
  }

  .box-Wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    gap: 10rem;
    font-size: 14px;
    line-height: 20px;

    @media (max-width: 992px) {
      gap: 80px;
    }
    @media (max-width: 885px) {
      gap: 40px;
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 0;
    }
  }
`;
