import React from "react";
import { Outlet, useLocation } from "react-router-dom";

const PublicLayout = () => {
  const location = useLocation().pathname;
  return (
    <div className="w-full min-h-dvh max-h-full md:h-dvh md:flex bg-[#f6f9fc]">
      {!location?.includes("logs") && (
        <div className="w-full bg-blue-300 h-[200px] md:h-full md:block relative">
          <div className="absolute inset-0 bg-[#0000004f]"></div>
          <img
            src="https://trioheatingandair.com/wp-content/uploads/2024/02/IMG_1420.jpg"
            alt="logo"
            className="w-full h-full object-cover"
          />
        </div>
      )}
      <div className="w-full py-5 md:py-0 h-full md:overflow-y-auto px-5 md:px-10">
        <Outlet />
      </div>
    </div>
  );
};

export default PublicLayout;
