import React, { useEffect } from "react";
import styled from "styled-components";
import JobsTabComp from "./JobsTabComp";
import { useDispatch, useSelector } from "react-redux";
import {
  manageForm,
  updateActiveIssue,
  updatePastIssue,
  updateVersions,
} from "store/slices/StmateSlice";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { getIssuesListing, updateIssueStatus } from "services/Collections";

export const Jobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stmateData = useSelector((state) => state?.tabSpecific?.STMate);
  const loginData = useSelector((state) => state?.shared?.LoginSlice?.data);

  const ChangeStatus = async (job, status) => {
    let key = loginData?.workspaces?.[0]?.key;
    let payload = {
      job: job,
      status: status,
    };
    await updateIssueStatus(key, payload);
  };

  const handleMovetoPast = (ele, index) => {
    let active = [...(stmateData?.activeIssuesList || [])];
    let past = [...(stmateData?.pastIssuesList || [])];

    let filteredData = active.filter((_, i) => i !== index);
    dispatch(updateActiveIssue(filteredData));

    let margePast = [];
    let flag = true;

    past.forEach((val) => {
      if (val?.job?.encoded_job === ele?.job?.encoded_job) {
        let newVal;
        newVal = {
          ...val,
          estimates: Array.from(
            new Set([...val?.estimates, ...ele?.estimates])
          ),
        };
        margePast.push(newVal);
        flag = false;
      } else {
        margePast.push(val);
      }
    });

    if (flag) {
      margePast.push(ele);
    }

    dispatch(updatePastIssue(margePast));

    ChangeStatus(ele?.job?.encoded_job, 0);
  };

  const handleMovetoActive = (ele, index) => {
    let past = [...(stmateData?.pastIssuesList || [])];
    let active = [...(stmateData?.activeIssuesList || [])];

    let filteredData = past?.filter((_, i) => i !== index);
    dispatch(updatePastIssue(filteredData));

    let margePast = [];
    let flag = true;

    active.forEach((val) => {
      if (val?.job?.encoded_job === ele?.job?.encoded_job) {
        let newVal;
        newVal = {
          ...val,
          estimates: Array.from(
            new Set([...ele?.estimates,...val?.estimates])
          ),
        };
        margePast.push(newVal);
        flag = false;
      } else {
        margePast.push(val);
      }
    });

    if (flag) {
      margePast.push(ele);
    }

    dispatch(updateActiveIssue(margePast));

    ChangeStatus(ele?.job?.encoded_job, 1);
  };

  const handleOnEdit = (estObj, selectedIndex = 0) => {
    let finalEstimates = [];

    estObj?.estimates?.forEach((est) => {
      let estimates = est?.versions?.map((ele, i) => {
        let aiSummary = ele?.ai_response
          ?.split("***")
          ?.filter((ele) => ele?.trim()?.length > 1 && ele);
        const aiTitle =
          (aiSummary?.length > 1 && aiSummary?.[aiSummary?.length - 1]) || "";
        let obj = {
          Summary: aiSummary?.[0],
          oldSummary: aiSummary?.[0],
          buttonStatus: ele?.pushed_status == null ? "push" : "pushed",
          title: aiTitle,
          oldTitle: aiTitle,
          parentId: est?.id,
          isActive: i === 0 ? true : false,
          version: ele?.id,
          jobId: estObj?.job?.encoded_job,
          jobName: est?.title,
        };

        if (ele?.pushed_status) {
          obj["id"] = ele?.id;
        }
        return obj;
      });

      finalEstimates.push(...estimates);
    });
    dispatch(
      manageForm(
        estObj?.estimates?.map((ele) => ({ ...ele, parentId: ele?.id }))
      )
    );
    dispatch(updateVersions(finalEstimates));
    navigate("/v1/stmate-form", { state: { selectedIndex } });
  };

  const handleActiveListing = async () => {
    let key = loginData?.workspaces?.[0]?.key;

    let param = new URLSearchParams();
    stmateData?.outsideJob && param.append("job", stmateData?.outsideJob);
    param.append("user_id", loginData?.id);
    param.append("status", 1);

    let res = await getIssuesListing(key, param);
    if (res?.status === 200) {
      dispatch(updateActiveIssue(res?.data || []));
    } else {
      dispatch(updateActiveIssue([]));
    }
  };

  const handlePastListing = async () => {
    let key = loginData?.workspaces?.[0]?.key;

    let param = new URLSearchParams();
    stmateData?.outsideJob && param.append("job", stmateData?.outsideJob);
    param.append("user_id", loginData?.id);
    param.append("status", 0);

    let res = await getIssuesListing(key, param);
    if (res?.status === 200) {
      dispatch(updatePastIssue(res?.data || []));
    } else {
      dispatch(updatePastIssue([]));
    }
  };

  useEffect(() => {
    handleActiveListing();
    handlePastListing();
  }, []);

  return (
    <JobsWrapper>
      <h1 className="header">Jobs</h1>

      {stmateData?.activeIssuesList?.length > 0  && (
        <div className="subHeader">
          <p className="status">Active</p>
          <div className="radioWraper">
            <p className="estimate">Estimates</p>
            <div className="circle">
              <div style={{ background: "#000" }}></div>Pushed
            </div>
            <div className="circle">
              <div style={{ background: "#d7d7d7" }}></div>Not Pushed
            </div>
          </div>
        </div>
      )}

      <div className="wrapper">
        { 
          stmateData?.activeIssuesList?.length > 0 && (
            <>
              {stmateData?.activeIssuesList?.map((ele, i) => (
                <JobsTabComp
                  key={ele?.job?.number}
                  type="Active"
                  data={ele}
                  moveToPast={() => handleMovetoPast(ele, i)}
                  onEdit={() => handleOnEdit(ele)}
                  singleEstimate={handleOnEdit}
                />
              ))}
            </>
          )
        }
      </div>

      {stmateData?.pastIssuesList?.length > 0 && (
        <div className="subHeader">
          <p className="status">Past</p>
          {stmateData?.activeIssuesList?.length === 0 && (
            <div className="radioWraper">
              <p className="estimate">Estimates</p>
              <div className="circle">
                <div style={{ background: "#000" }}></div>Pushed
              </div>
              <div className="circle">
                <div style={{ background: "#d7d7d7" }}></div>Not Pushed
              </div>
            </div>
          )}
        </div>
      )}

      <div className="wrapper">
        {stmateData?.pastIssuesList?.length > 0 && (
          <>
            {stmateData?.pastIssuesList?.map((ele, i) => (
              <JobsTabComp
                key={ele?.id}
                type="Past"
                data={ele}
                movetoActive={() => handleMovetoActive(ele, i)}
                onEdit={() => handleOnEdit(ele)}
                singleEstimate={handleOnEdit}
              />
            ))}
          </>
        )}
      </div>

      {stmateData?.pastIssuesList?.length === 0 &&
        stmateData?.activeIssuesList?.length === 0 && (
          <div className="wrapper">
            <div className="noData">
              <IoSearchOutline size={80} /> No Data Available
            </div>
          </div>
        )}
    </JobsWrapper>
  );
};

const JobsWrapper = styled.div`
  padding: 20px 20px;
  width: 100%;
  height: calc(100vh - 50px);
  overflow: auto;

  .header {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .noData {
      color: #4a494991;
      font-weight: 600;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 15px 0 10px;
      border-radius: 10px;
      height: calc(100vh - 255px);
      font-size: 24px;
    }
  }

  .subHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 5px;
    margin: 15px 0;

    .status {
      font-size: 20px;
      font-weight: 600;
    }
    .radioWraper {
      display: flex;
      gap: 20px;
      align-items: baseline;

      .circle {
        display: flex;
        gap: 5px;
        align-items: center;
        div {
          width: 15px;
          height: 15px;
          border-radius: 100%;
        }
      }

      .estimate {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
`;
