import ChangePassword from "auth/ChangePassword";
import { Congratulations } from "auth/Congratulations";
import CreateAccount from "auth/CreateAccount";
import ForgotPassword from "auth/ForgotPassword";
import Login from "auth/Login";
import SetNewPassword from "auth/SetNewPassword";
import Step1 from "auth/Steps/Step1";
import InviteCRM from "auth/Steps/Step2";
import PromptLogComp from "pages/promptLog/PromptLogComp";

export const PublicPaths = [
  {
    path: "/",
    component: <Login />,
  },
  {
    path: "/signup",
    component: <CreateAccount />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
  {
    path: "/congratulation",
    component: <Congratulations />,
  },
  {
    path: "/step-1",
    component: <Step1 />,
  },
  {
    path: "/step-2",
    component: <InviteCRM />,
  },
  {
    path: "/set-new-password",
    component: <SetNewPassword />,
  },
  {
    path: "/change-password",
    component: <ChangePassword />,
  },
  {
    path: "/logs",
    component: <PromptLogComp />,
  },
];
