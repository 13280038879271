import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { SigninInitialStateReset } from "store/slices/SignInSlice";
import { resetStmateSlice } from "store/slices/StmateSlice";

const useBroadcastChannel = (store) => {
  const previousStateRef = useRef(store.getState().shared.LoginSlice); // Initialize at the top level
  const dispatch = useDispatch();

  useEffect(() => {
    const channel = new BroadcastChannel("shared-state-channel");

    // Handle incoming messages from the BroadcastChannel
    channel.onmessage = (event) => {
      if (event.data.type === "UPDATE_SHARED_STATE") {
        const currentState = store.getState().shared;
        if (
          JSON.stringify(currentState) !== JSON.stringify(event.data.payload)
        ) {
          if (event?.data?.payload?.data) {
            dispatch(SigninInitialStateReset(event.data.payload));
          } else {
            dispatch(SigninInitialStateReset());
            dispatch(resetStmateSlice());
          }
        }
      }
    };

    // Subscribe to Redux store updates
    const unsubscribe = store.subscribe(() => {
      const currentState = store.getState().shared?.LoginSlice;
      if (
        JSON.stringify(previousStateRef.current) !==
        JSON.stringify(currentState)
      ) {
        previousStateRef.current = currentState; // Update the reference
        channel.postMessage({
          type: "UPDATE_SHARED_STATE",
          payload: currentState,
        });
      }
    });

    // Cleanup
    return () => {
      unsubscribe(); // Unsubscribe from the Redux store
      channel.close(); // Close the BroadcastChannel
    };
  }, [store]); // Ensure effect runs when the store changes
};

export default useBroadcastChannel;
