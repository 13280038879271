/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SigninInitialStateReset } from "store/slices/SignInSlice";
import { DeleteModal } from "./DeleteModal";
import {
  Dashboard,
  GroupIcon,
  JobsIcon,
  SubMenuDiamond,
  Team,
} from "../utils/Icons/SvgIcons";
import { resetStmateSlice } from "store/slices/StmateSlice";

export default function Sidenav({ arrowbutton, setArrowbutton }) {
  const location = useLocation()?.pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logoutModal, setLogout] = useState(false);
  const [subMenu, setSubMenu] = useState(false);
  const currentActiveJob = useSelector((state) => state?.tabSpecific?.STMate?.outsideJob);

  const handleLogout = () => {
    dispatch(resetStmateSlice());
    dispatch(SigninInitialStateReset());
  };

  const handleArrowButton = () => {
    // setSubMenu(false);
    setArrowbutton((pre)=> !pre);
  };

  return (
    <SidenavWrapper>
      {logoutModal && (
        <DeleteModal
          handleClose={() => setLogout(false)}
          open={logoutModal}
          title="Are you sure you want to Log out ?"
          description="All your process and data will be saved."
          button="Log out"
          btnColor="#012e61"
          handleSubmit={() => handleLogout()}
        />
      )}

      <section>
        <div>
          {arrowbutton ? (
            ""
          ) : (
            <div className="search-input">
              {/* <input
              type="search"
              placeholder="Search"
              disabled
              onFocus={() => setOpenSearch(!openSearch)}
            /> */}
              <i className="searchIconWrap">{/* <Search /> */}</i>
              Search
            </div>
          )}
          <div style={{ height: "100%" }} id="tabsections">
            <div className="all-tabs">
              <div
                style={{ margin: "5px 0" }}
                className={location?.includes("jobs") ? "active" : undefined}
                onClick={() => {
                  currentActiveJob
                    ? navigate(`jobs/${currentActiveJob}`)
                    : navigate("jobs");
                  setSubMenu(false);
                  handleArrowButton();
                }}
              >
                {arrowbutton ? (
                  <Team />
                ) : (
                  <>
                    <Team />
                    <p>STMate</p>
                  </>
                )}
              </div>
            </div>
            <div className="all-tabs">
              <div
                style={{ margin: "5px 0" }}
                className={
                  location?.includes("job-chat") ? "active" : undefined
                }
                onClick={() => {
                  navigate("job-chat");
                  setSubMenu(false);
                  handleArrowButton();
                }}
              >
                {arrowbutton ? (
                  <JobsIcon />
                ) : (
                  <>
                    <JobsIcon />
                    <p>Jobs</p>
                  </>
                )}
              </div>
            </div>
            {/* <div className="all-tabs">
              <div
                style={{ margin: "5px 0" }}
                className={location?.includes("group") && "active"}
                onClick={() => {
                  navigate("group");
                  setSubMenu(false);
                }}
              >
                <GroupIcon />
                <p>Group Chat</p>
              </div>
            </div> */}
            <div className="all-tabs">
              {/* <div
                style={{ margin: "5px 0" }}
                className={location?.includes("team") && "active"}
                onClick={() => {
                  navigate("team");
                  setSubMenu(false);
                }}
              >
                {arrowbutton ? (
                  <TeamIcon />
                ) : (
                  <>
                    <TeamIcon />
                    <p>Team Chat</p>
                  </>
                )}
              </div> */}
            </div>

            <div className="all-tabs">
              <div
                onClick={() => {
                  setSubMenu(!subMenu);
                }}
                className={
                  location?.includes("business") ||
                  location?.includes("users") ||
                  location?.includes("managers") ||
                  location?.includes("job-type")
                    ? "active"
                    : undefined
                }
              >
                {arrowbutton ? (
                  <Dashboard />
                ) : (
                  <>
                    <Dashboard />
                    <p>Workspace Settings</p>
                  </>
                )}
              </div>
              {subMenu && (
                <div className="sub-menu">
                  <div className="menu">
                    {/* <div
                      className={location?.includes("regions") && "Subactive"}
                      onClick={() => navigate("regions")}
                    >
                      <SubMenuDiamond />
                      <p>Regions</p>
                    </div> */}
                    <div
                      className={
                        location?.includes("business") ? "active" : undefined
                      }
                      onClick={() => {navigate("business");handleArrowButton();}}
                    >
                      <SubMenuDiamond />
                      <p>Business Units</p>
                    </div>
                    <div
                      className={
                        location?.includes("job-types") ? "active" : undefined
                      }
                      onClick={() => {navigate("job-types");handleArrowButton();}}
                    >
                      <SubMenuDiamond />
                      <p>Job Types</p>
                    </div>

                    <div
                      className={
                        location?.includes("users") ? "active" : undefined
                      }
                      onClick={() => {navigate("users");handleArrowButton();}}
                    >
                      <SubMenuDiamond />
                      <p>Users</p>
                    </div>

                    <div
                      className={
                        location?.includes("managers") ? "active" : undefined
                      }
                      onClick={() => {navigate("managers");handleArrowButton();}}
                    >
                      <SubMenuDiamond />
                      <p>Managers</p>
                    </div>

                    {/* <div
                      className={location?.includes("settings") && "active"}
                      onClick={() => {
                        navigate("settings");
                        // setSubMenu(false);
                      }}
                    >
                      <SubMenuDiamond />
                      <p>ST Settings</p>
                    </div> */}

                    <div
                      className={
                        location?.includes("drag-tree") ? "active" : undefined
                      }
                      onClick={() => {
                        navigate("drag-tree");
                        // setSubMenu(false);
                        handleArrowButton();
                      }}
                    >
                      <SubMenuDiamond />
                      <p>ST Settings</p>
                    </div>

                    {/* <div>
                      <SubMenuDiamond />
                      <p>Import to ST Job</p>
                    </div> */}

                    {/* <div className="all-tabs">
                      <div
                        style={{ margin: "5px 0" }}
                        className={location?.includes("settings") && "active"}
                        onClick={() => {
                          navigate("settings");
                          setSubMenu(false);
                        }}
                      >
                        {arrowbutton ? 
                          <SettingNavIcon />
                         : 
                          <>
                            <SettingNavIcon />
                            <p>ST Settings</p>
                          </>
                        }
                      </div>
                    </div> */}
                  </div>
                </div>
              )}
            </div>

            {/* <div className="all-tabs">
              <div
                style={{ margin: "5px 0" }}
                className={location?.includes("users") && "active"}
                onClick={() => {
                  navigate("users");
                  setSubMenu(false);
                }}
              >
                <Team />
                <p>Users</p>
              </div>
            </div> */}

            {/* <SettingSideNav calcHeight={"250"} /> */}
          </div>
        </div>

        <div className="sidebar-footer">
          <div
            onClick={() => {
              setLogout(true);
              setSubMenu(false);
            }}
          >
            {/* <ProfileExit /> */}
            <p>Logout</p>
          </div>
        </div>
      </section>
    </SidenavWrapper>
  );
}

const SidenavWrapper = styled.div`
  width: 100%;
  background: linear-gradient(172deg, #41628a, #456180);
  padding: 32px 16px;
  transition: all 0.3s;
  position: relative;
  height: 100%;

  .search-content {
    width: 100%;
    height: 100%;
    max-height: 70vh;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #cecece;
      border-radius: 10px;
    }
  }

  .search-input {
    background: #541e56;
    padding: 0 10px;
    position: relative;
    display: flex;
    margin: 12px 16px;
    background: #fff;
    border: 1px solid transparent;
    height: 40px;
    border-radius: 5px;
    padding: 0px 10px;
    padding-left: 20px;
    color: gray;
    border: 1px solid rgb(151 151 151 / 53%);
    align-items: center;
    gap: 10px;
    cursor: text;

    .searchIconWrap {
      margin-top: 5px;

      svg {
        width: 15px;
      }
    }
  }

  header {
    img {
      width: 120px;
      margin: 0 26px;
    }
  }
  .sub-menu {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    padding: 0 !important;
  }

  .menu {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    margin: 0 !important;
    padding: 0 !important;
    padding: 5px 20px !important;

    div {
      width: 100%;
      padding: 7px 10px !important;
    }
    p {
      white-space: nowrap;
    }
  }

  .Three-lines {
    position: absolute;
    top: 15px;
    right: 18px;
    cursor: pointer;
  }

  section {
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;

    .all-tabs {
      width: 100%;
      margin: 6px 0;
      color: #ffffff;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;

      .active {
        background: #9ac0ed70;
        border-radius: 500px;
      }
      .Subactive {
        border-left: 4px solid #fff;
        background: #9ac0ed70;
        border-radius: 15px 0;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: start;
        margin: 8px 0;
        cursor: pointer;

        padding: 12px 16px;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }
    }
    .sidebar-footer {
      width: 100%;
      margin: 32px 0 0 0;
      color: #ffffff;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;

      /* @media (max-width: 800px) {
        display: none;
      } */

      div {
        display: flex;
        align-items: center;
        justify-content: start;
        margin: 8px 0;
        cursor: pointer;
        // background: #343434;
        border-radius: 500px;
        padding: 12px 16px;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }
    }
  }
`;
