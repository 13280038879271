import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import SignInSlice from "./slices/SignInSlice";
import StmateSlice from "./slices/StmateSlice";
import SettingsSlice from "./slices/SettingSlice";
import storageSession from "redux-persist/lib/storage/session";

const sharedReducers = combineReducers({
  LoginSlice: SignInSlice,
});

const sharedPersistConfig = {
  key: "STMate1.0.9",
  storage, 
};

const tabSpecificReducers = combineReducers({
  STMate: StmateSlice,
  Settings: SettingsSlice,
});

const tabSpecificPersistConfig = {
  key: "tab-STMate1.0.9",
  storage: storageSession,
};

const sharedPersistedReducer = persistReducer(sharedPersistConfig, sharedReducers);
const tabSpecificPersistedReducer = persistReducer(tabSpecificPersistConfig, tabSpecificReducers);


export const store = configureStore({
  reducer: {
    shared: sharedPersistedReducer,
    tabSpecific: tabSpecificPersistedReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export const persistor = persistStore(store);
