import {
  deleteIssueStatus,
  PushHistoryToSt,
  SubmitSummary,
  UpdatePushTOSt,
} from "services/Collections";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Loader } from "components/Loader";
import { styled } from "styled-components";
import Header from "./Header";
import { TestDiv } from "./SubHeader";
import {
  manageForm,
  updateActiveIssue,
  updatePastIssue,
  updateTimeForGtm,
  updateVersions,
} from "store/slices/StmateSlice";
import { useDispatch } from "react-redux";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import {
  pushBtnInActiveStyle,
  pushBtnActiveStyle,
  updateStyle,
} from "./staticData";
import { FiFilePlus, FiUploadCloud } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import ReWriteSummary from "./ReWriteSummary";
import { DeleteModal } from "components/DeleteModal";
import { truncateTo100Words } from "utils/Functions";
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiMiniArrowTopRightOnSquare } from "react-icons/hi2";
import { FiEdit } from "react-icons/fi";
import { PiCheckBold } from "react-icons/pi";
import { IoCloudOfflineOutline } from "react-icons/io5";
import { Popover } from "antd";
import useWindowDimensions from "components/hook/useWindowDimensions";
import { SlActionUndo, SlActionRedo } from "react-icons/sl";
import MakeUpdate from "./MakeUpdate";
import { useLocation, useNavigate } from "react-router-dom";

export default function Form() {
  const location = useLocation()?.state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stmateData = useSelector((state) => state?.tabSpecific?.STMate);
  const loginData = useSelector((state) => state?.shared?.LoginSlice?.data);
  const {
    formData,
    timeForGtm,
    versions: selectedFormVersions,
    outsideJob,
    header: headerData,
  } = stmateData;
  const workspace = loginData?.workspaces?.[0];

  const textAreaRef = useRef(null);
  const summaryTitleRef = useRef(null);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pushLoader, setPushLoader] = useState(false);
  const [name, setName] = useState("");
  const [textField, setTextFiled] = useState("");
  const [header, setHeader] = useState(false);
  const [summaryTitle, setSummatyTitle] = useState("");
  const [versionIndex, setVersionIndex] = useState(
    location?.selectedIndex ?? 0
  );
  const [openRewrite, setOpenRewrite] = useState(false);
  const [deleteSummary, setDeleteSummary] = useState(false);
  const [threeDots, setThreeDots] = useState(false);
  const [openMakeUpdate, setOpenMakeUpdate] = useState(false);

  const [estimate, setEstimate] = useState(null);
  const [selectedVersions, setSelectedVersions] = useState([]);
  const [activeVersion, setActiveVersion] = useState(null);

  const { width } = useWindowDimensions();

  const handleDeleteSummaryApi = async () => {
    let key = loginData?.workspaces?.[0]?.key;
    await deleteIssueStatus(key, estimate?.parentId);
  };

  const handleDeleteSummary = () => {
    if (
      stmateData?.activeIssuesList?.find(
        (ele) => ele?.id === estimate?.parentId
      )
    ) {
      let active = [...stmateData?.activeIssuesList] || [];
      let filteredData = active?.filter(
        (ele) => ele?.id !== estimate?.id && ele
      );
      dispatch(updateActiveIssue(filteredData));
    }
    if (
      stmateData?.pastIssuesList?.find((ele) => ele?.id === estimate?.parentId)
    ) {
      let active = [...stmateData?.pastIssuesList] || [];
      let filteredData = active?.filter(
        (ele) => ele?.id !== estimate?.id && ele
      );
      dispatch(updatePastIssue(filteredData));
    }
    dispatch(
      manageForm(
        formData?.filter((ele) => estimate?.parentId !== ele?.parentId && ele)
      )
    );
    dispatch(
      updateVersions(
        selectedFormVersions?.filter(
          (ele) => estimate?.parentId !== ele?.parentId && ele
        )
      )
    );
    toast.success("Variation deleted successfully");
    handleDeleteSummaryApi();
    setVersionIndex(0);
    setDeleteSummary(false);
  };

  const handleReWriteClose = () => {
    setOpenRewrite(false);
    setName("");
  };

  const handleMakeUpdateClose = () => {
    setOpenMakeUpdate(false);
    setName("");
  };

  const AutoSelectTitle = () => {
    if (summaryTitleRef.current) {
      summaryTitleRef.current.select();
    }
  };

  const handleReWriteSummary = async (switchStatus) => {
    if (name == "" || name == undefined || name?.trim()?.length == 0) {
      toast.error("Please Add Instructions");
      return;
    }
    if (
      textField == "" ||
      textField == undefined ||
      textField?.trim()?.length == 0
    ) {
      toast.error("Please Enter Summary");
      return;
    }
    setLoading(true);
    const dataObj = {
      job: data?.jobId || outsideJob,
      title:
        data?.jobName ||
        `${headerData?.data?.job_type} || ${headerData?.data?.customer}`,
      user_id: loginData?.id,
      summary: textField,
      prompt: name,
      new_estimate: switchStatus,
    };

    if (!switchStatus) {
      dataObj["est_id"] = estimate?.parentId;
    }

    const formValue = truncateTo100Words(name);

    if (window.dataLayer) {
      if (switchStatus) {
        window.dataLayer.push({
          event: "new_estimate_submission",
          form_value: formValue,
          Job_id: outsideJob,
          refine_id: timeForGtm,
        });
      } else {
        window.dataLayer.push({
          event: "form_rewrite_submission",
          form_value: formValue,
          Job_id: outsideJob,
          refine_id: timeForGtm,
        });
      }
    }

    const res = await SubmitSummary(workspace?.key, dataObj);
    console.log("Instruction GPT Response: ", res);
    if (res?.status == 200) {
      let apiData = res?.data?.ai_response
        ?.split("***")
        ?.filter((ele) => ele?.trim()?.length > 1 && ele);
      const aiTitle =
        (apiData?.length > 1 && apiData?.[apiData?.length - 1]) || "";
      let newSummary = {
        Summary: apiData?.[0],
        oldSummary: apiData?.[0],
        RecommendedServices: [],
        buttonStatus: "Push",
        title: aiTitle,
        oldTitle: aiTitle,
        isActive: true,
        parentId: switchStatus ? res?.data?.estimate : estimate?.parentId,
        version: res?.data?.version,
        jobId: data?.jobId || outsideJob,
        jobName:
          data?.jobName ||
          `${headerData?.data?.job_type} || ${headerData?.data?.customer}`,
      };
      const formValueRes = truncateTo100Words(apiData?.[0]);
      if (window.dataLayer) {
        if (switchStatus) {
          window.dataLayer.push({
            event: "new_estimate_response",
            form_value: formValueRes,
            Job_id: outsideJob,
            refine_id: timeForGtm,
          });
        } else {
          window.dataLayer.push({
            event: "form_rewrite_reponse",
            form_value: formValueRes,
            Job_id: outsideJob,
            refine_id: timeForGtm,
          });
        }
      }
      if (switchStatus) {
        dispatch(manageForm([...formData, newSummary]));
        setVersionIndex(formData?.length);
        setEstimate(newSummary)
      }
      let updatedVer = switchStatus
        ? selectedFormVersions
        : selectedFormVersions?.map((ele) =>
            ele?.parentId == estimate?.parentId
              ? { ...ele, isActive: false }
              : ele
          );
      const updateFormData = [...updatedVer, newSummary];
      dispatch(updateVersions(updateFormData));

      if (switchStatus) {
        setSelectedVersions([newSummary]);
        setActiveVersion(0);
      } else {
        setSelectedVersions((pre) => [...pre, newSummary]);
        setActiveVersion(selectedVersions?.length);
      }
      setName("");
      setTextFiled(apiData?.[0]);
      setSummatyTitle(aiTitle);
      setData(newSummary);
      setOpenMakeUpdate(false);

      toast.success("Summary Re-write Successfully.", {
        style: {
          color: "black",
        },
      });
      setOpenRewrite(false);
    } else {
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
      setOpenMakeUpdate(false);
      setOpenRewrite(false);
    }
    setLoading(false);
  };

  const handlePushToSt = async (text) => {
    if (
      textField == "" ||
      textField == undefined ||
      textField?.trim()?.length == 0
    ) {
      toast.error("Please Enter Summary");
      return;
    }
    if (
      summaryTitle == "" ||
      summaryTitle == undefined ||
      summaryTitle?.trim()?.length == 0
    ) {
      toast.error("Please Enter Title");
      return;
    }
    setPushLoader(true);
    let res;
    if (text == "Update") {
      let obj = {
        title: summaryTitle,
        summary: textField,
      };
      res = await UpdatePushTOSt(workspace?.key, data?.id, obj);
    } else {
      let num = data?.version;
      let obj = {
        user_id: loginData?.id,
        job_id: data?.jobId || outsideJob,
        title: summaryTitle,
        summary: textField,
        version_id: num?.toString(),
      };
      res = await PushHistoryToSt(workspace?.key, obj);
    }
    if (res?.status == 200 || res?.status == 201) {
      // const cloneFormData = [...formData];
      let updateData = {
        oldSummary: textField,
        oldTitle: summaryTitle,
        buttonStatus: "Pushed",
        id: res?.data?.id,
      };
      // let updateFormData = cloneFormData?.map((ele, i) =>
      //   i == versionIndex ? { ...ele, ...updateData } : ele
      // );
      let ver = selectedVersions?.map((ele, index) =>
        index == activeVersion ? { ...ele, ...updateData } : ele
      );
      let totalVersions = selectedFormVersions?.filter(
        (ele) => ele?.parentId !== estimate?.parentId
      );
      setSelectedVersions(ver);

      dispatch(updateVersions([...ver, ...totalVersions]));
      // dispatch(manageForm(updateFormData));
      setData((pre) => ({ ...pre, ...updateData }));
      setThreeDots(false);

      let toastMessage = text == "Update" ? "Updated" : "Push";
      toast.success(
        `${toastMessage || "Push"} to Service Titan Successfully.`,
        {
          style: {
            color: "black",
          },
        }
      );
    } else {
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
    }
    setPushLoader(false);
  };

  const handleVersionSummary = (index) => {
    updateFields(index);
    // const updateFormData = [...formData];
    // let ActiveSummary = updateFormData?.[index];
    // setName("");
    // setData(ActiveSummary);
    // setSummatyTitle(ActiveSummary?.title);
    // setTextFiled(ActiveSummary?.Summary);
  };

  const updateTitle = (value) => {
    setSummatyTitle(value);

    // let formObj = formData?.[versionIndex];
    let formObj = selectedVersions?.[activeVersion];

    let buttonText = "Push";
    if (formObj?.id) {
      if (formObj?.oldTitle?.trim() == value?.trim()) {
        buttonText = "Pushed";
      } else {
        buttonText = "Update";
      }
    }
    // const cloneFormData = [...formData];
    // let updateFormData = cloneFormData?.map((ele, i) =>
    //   i == versionIndex
    //     ? { ...ele, buttonStatus: buttonText, title: value }
    //     : ele
    // );
    // dispatch(manageForm(updateFormData));
    let ver = selectedVersions?.map((ele, index) =>
      index == activeVersion
        ? { ...ele, buttonStatus: buttonText, title: value }
        : ele
    );
    let totalVersions = selectedFormVersions?.filter(
      (ele) => ele?.parentId !== estimate?.parentId
    );
    setSelectedVersions(ver);

    dispatch(updateVersions([...ver, ...totalVersions]));

    setData((pre) => ({
      ...pre,
      buttonStatus: buttonText,
      title: value,
    }));
  };

  const updateSummary = (value) => {
    setTextFiled(value);

    // let formObj = formData?.[versionIndex];
    let formObj = selectedVersions?.[activeVersion];

    let buttonText = "Push";
    if (formObj?.id) {
      if (formObj?.oldTitle?.trim() == value?.trim()) {
        buttonText = "Pushed";
      } else {
        buttonText = "Update";
      }
    }

    // const cloneFormData = [...formData];
    // let updateFormData = cloneFormData?.map((ele, i) =>
    //   i == versionIndex
    //     ? { ...ele, buttonStatus: buttonText, Summary: value }
    //     : ele
    // );
    // dispatch(manageForm(updateFormData));

    let ver = selectedVersions?.map((ele, index) =>
      index == activeVersion
        ? { ...ele, buttonStatus: buttonText, Summary: value }
        : ele
    );
    let totalVersions = selectedFormVersions?.filter(
      (ele) => ele?.parentId !== estimate?.parentId
    );
    setSelectedVersions(ver);

    dispatch(updateVersions([...ver, ...totalVersions]));

    setData((pre) => ({
      ...pre,
      buttonStatus: buttonText,
      Summary: value,
    }));
  };

  const updateFields = (activeEstimate) => {
    let index = activeEstimate ?? versionIndex;
    let data = selectedFormVersions?.filter(
      (ele) => ele?.parentId == formData?.[index]?.parentId
    );

    setSelectedVersions(data);
    setEstimate(formData?.[index]);

    data?.forEach((ele, i) => ele?.isActive && setActiveVersion(i));
    let selected = data?.find((ele) => ele?.isActive);

    setData(selected || {});
    setName("");
    setTextFiled(selected?.Summary || "");
    setSummatyTitle(selected?.title || "");
  };

  useEffect(() => {
    if (!deleteSummary) {
      updateFields();
      setVersionIndex(location?.selectedIndex || 0);
    }
    if (location?.selectedIndex) {
      navigate("/v1/stmate-form", { replace: true, state: null });
    }
  }, [deleteSummary]);

  const handlePushBtn = () => {
    let formObj = selectedVersions?.[activeVersion];
    // let buttonText = "Push to Service Titan";
    let buttonText = "Push";
    if (formObj?.id) {
      if (
        formObj?.oldTitle?.trim() == summaryTitle?.trim() &&
        formObj?.oldSummary?.trim() == textField?.trim()
      ) {
        // buttonText = "Pushed to Service Titan";
        buttonText = "Pushed";
      } else {
        // buttonText = "Update Service Titan";
        buttonText = "Update";
      }
    }
    return buttonText;
  };

  const PushToStStauts = useMemo(
    () => handlePushBtn(),
    [
      textField,
      summaryTitle,
      formData[versionIndex],
      selectedVersions[activeVersion],
    ]
  );

  if (loading || data == null) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Loader size={34} color={"#000"} />
      </div>
    );
  }

  const handleRewrite = (type) => {
    const secondsSinceEpoch = Math.floor(Date.now() / 1000);
    dispatch(updateTimeForGtm(secondsSinceEpoch));

    if (type === "update") {
      setOpenMakeUpdate(true);
    } else {
      setOpenRewrite(true);
    }
  };

  const ActivePushBtn = {
    Pushed: {
      name: "Open in ST",
      icon: <HiMiniArrowTopRightOnSquare size="22px" />,
    },
    Push: {
      name: "Push to ST",
      icon: <FiUploadCloud size="22px" />,
    },
    Update: {
      name: "Push Edits",
      icon: <FiUploadCloud size="22px" />,
    },
  };

  const handleUndoRedo = (index) => {
    if (index < 0) {
      return;
    }
    let updatedSelectedVersions = selectedVersions?.map((ele, i) => ({
      ...ele,
      isActive: i == index,
    }));

    setSelectedVersions(updatedSelectedVersions);

    let totalVersions = selectedFormVersions?.filter(
      (ele) => ele?.parentId !== estimate?.parentId
    );

    dispatch(updateVersions([...updatedSelectedVersions, ...totalVersions]));

    setActiveVersion(index);

    setData(selectedVersions[index] || {});
    setName("");
    setTextFiled(selectedVersions?.[index]?.Summary || "");
    setSummatyTitle(selectedVersions?.[index]?.title || "");
  };

  const content = (
    <PopContent>
      <p
        className="hideInMobile"
        onClick={() => {
          handleRewrite();
          setThreeDots(false);
        }}
      >
        <FiFilePlus size={"22px"} /> New Estimate
      </p>
      <p
        className="hideInMobile"
        onClick={() => {
          handleRewrite("update");
          setThreeDots(false);
        }}
      >
        <FiEdit size={"22px"} /> Make Updates
      </p>
      {selectedVersions?.length > 0 && activeVersion > 0 ? (
        <p onClick={() => handleUndoRedo(activeVersion - 1)}>
          <SlActionUndo size={"22px"} /> Undo Update
        </p>
      ) : (
        <p className="disable">
          <SlActionUndo size={"22px"} /> Undo Update
        </p>
      )}
      {selectedVersions?.length > 1 &&
      activeVersion < selectedVersions?.length - 1 ? (
        <p onClick={() => handleUndoRedo(activeVersion + 1)}>
          <SlActionRedo size={"22px"} /> Redo Update
        </p>
      ) : (
        <p className="disable">
          <SlActionRedo size={"22px"} /> Redo Update
        </p>
      )}
      {formData?.length > 1 && (
        <p
          onClick={() => {
            setDeleteSummary(true);
            setThreeDots(false);
          }}
        >
          <RiDeleteBinLine size={"23px"} /> Delete This
        </p>
      )}
      {"Pushed" !== PushToStStauts && (
        <p
          className="hideInMobile"
          onClick={() =>
            PushToStStauts !== "Pushed" && handlePushToSt(PushToStStauts)
          }
        >
          {pushLoader ? (
            <>
              <Loader color="#000" size="22px" /> Pushing to ST
            </>
          ) : (
            <>
              {ActivePushBtn?.[PushToStStauts]?.icon}{" "}
              {ActivePushBtn?.[PushToStStauts]?.name}
            </>
          )}
        </p>
      )}
      {data?.id && (
        <p className="hideInMobile">
          <>
            {ActivePushBtn?.["Pushed"]?.icon} {ActivePushBtn?.["Pushed"]?.name}
          </>
        </p>
      )}
    </PopContent>
  );

  return (
    <FormWrapper>
      <TestDiv>
        <Header
          jobDetails={headerData?.data}
          header={header}
          setHeader={setHeader}
        />
        <div className="w-full pt-2 bg-[#d9d9d9] flex justify-end md:justify-between items-center pl-[15px]">
          <ButtonStyle
            notShow={true}
            active={true}
            btnStyle={{ margin: "0 0 8px 0" }}
            onClick={() => handleRewrite()}
          >
            <FiFilePlus size={"22px"} /> New Estimate
          </ButtonStyle>

          <div className="veriationSection">
            <VersionStyleTab>
              <button
                onClick={() =>
                  versionIndex != 0 &&
                  (setVersionIndex((pre) => pre - 1),
                  handleVersionSummary(versionIndex - 1))
                }
                style={{
                  background: versionIndex !== 0 ? "#d0cdcd" : "#f6f6f6",
                  padding: "0 8px",
                }}
              >
                {versionIndex !== 0 && (
                  <MdArrowBackIos
                    color="black"
                    style={{
                      cursor: versionIndex == 0 ? "default" : "pointer",
                      marginLeft: "4px",
                    }}
                  />
                )}
              </button>

              <div className="summaryTitle">
                <span>Estimate {versionIndex + 1} </span>
                of {formData?.length}
              </div>

              <button
                onClick={() =>
                  versionIndex != formData?.length - 1 &&
                  (setVersionIndex((pre) => pre + 1),
                  handleVersionSummary(versionIndex + 1))
                }
                style={{
                  background:
                    versionIndex !== formData?.length - 1
                      ? "#d0cdcd"
                      : "#f6f6f6",
                }}
              >
                {versionIndex !== formData?.length - 1 && (
                  <MdArrowForwardIos
                    color="black"
                    style={{
                      cursor:
                        versionIndex == formData?.length - 1
                          ? "default"
                          : "pointer",
                    }}
                  />
                )}
              </button>
            </VersionStyleTab>

            <ButtonStyle
              notShow={true}
              btnStyle={updateStyle}
              onClick={() => handleRewrite("update")}
            >
              <FiEdit size={"22px"} /> Update
            </ButtonStyle>
            <ButtonStyle
              notShow={true}
              // active={PushToStStauts == "Pushed to Service Titan" ? false : true}
              disabled={PushToStStauts == "Pushed" ? true : false}
              btnStyle={
                PushToStStauts == "Pushed"
                  ? pushBtnInActiveStyle
                  : pushBtnActiveStyle
              }
              onClick={() => handlePushToSt(PushToStStauts)}
            >
              {pushLoader ? (
                <>
                  {/* <Loader color="#fff" size="20px" /> Pushing to Service Titan */}
                  <Loader color="#fff" size="20px" /> Pushing
                </>
              ) : (
                <>
                  <FiUploadCloud size={"22px"} /> {PushToStStauts}
                </>
              )}
            </ButtonStyle>
            {/* {formData?.length > 1 && (
              <i className="deleteIcon" onClick={() => setDeleteSummary(true)}>
                <RiDeleteBinLine size={"25px"} />
              </i>
            )} */}
            <Popover
              placement="bottomLeft"
              content={content}
              trigger="click"
              open={threeDots}
              arrow={false}
              onOpenChange={() => setThreeDots(!threeDots)}
              prefixCls="mobilPop"
            >
              <i className="threeDotIcon">
                <BsThreeDotsVertical size={"25px"} />
              </i>
            </Popover>
          </div>
        </div>
      </TestDiv>
      <div className="w-full mx-auto md:px-4 bg-[#f6f6f6] rounded-lg">
        <div className="flex gap-6">
          <div className="w-full col-span-2 mt-5 mx-3 flex flex-col">
            <div className="rounded-[20px] mb-4">
              <div className="flex flex-col md:flex-row items-start gap-3 mb-3">
                <p className="flex justify-between w-full md:w-fit text-base font-semibold">
                  Title
                  {width < 768 && (
                    <StatusOFSummary
                      status={PushToStStauts}
                      clickTOPush={() => handlePushToSt(PushToStStauts)}
                    />
                  )}
                </p>
                <TextAreaStyle
                  ref={summaryTitleRef}
                  onFocus={AutoSelectTitle}
                  style={{ padding: "10px" }}
                  height="46px"
                  placeholder="Enter title"
                  value={summaryTitle}
                  onChange={(e) => updateTitle(e.target.value)}
                />
              </div>
              <TextAreaStyle
                // height={`calc(100vh - ${348 + (inputHeight || 50)}px)`}
                height={
                  width < 767 ? `calc(100vh - 300px)` : `calc(100vh - 264px)`
                }
                ref={textAreaRef}
                placeholder="Enter Summary"
                value={textField}
                onChange={(e) => updateSummary(e.target.value)}
              />
            </div>
          </div>

          {openRewrite && (
            <ReWriteSummary
              name={name}
              setName={setName}
              open={openRewrite}
              close={handleReWriteClose}
              submit={handleReWriteSummary}
            />
          )}

          {openMakeUpdate && (
            <MakeUpdate
              name={name}
              setName={setName}
              open={openMakeUpdate}
              close={handleMakeUpdateClose}
              submit={handleReWriteSummary}
            />
          )}

          {deleteSummary && (
            <DeleteModal
              handleClose={() => setDeleteSummary(false)}
              open={deleteSummary}
              title="Are you sure you want to delete this Estimate?"
              button="Delete"
              btnColor="#012e61"
              handleSubmit={handleDeleteSummary}
            />
          )}
        </div>
      </div>
    </FormWrapper>
  );
}

const StatusOFSummary = ({ status, clickTOPush }) => {
  return (
    <>
      {status == "Pushed" ? (
        <span className="flex gap-1 items-center text-sm">
          Pushed to ST <PiCheckBold />
        </span>
      ) : status == "Push" ? (
        <span
          onClick={clickTOPush}
          className="flex gap-[8px] items-center text-red-600 text-sm cursor-pointer"
        >
          Not Pushed <IoCloudOfflineOutline />
        </span>
      ) : (
        <span
          onClick={clickTOPush}
          className="flex gap-1 items-center text-sm cursor-pointer"
        >
          Push Edits <FiUploadCloud />
        </span>
      )}
    </>
  );
};

const FormWrapper = styled.div`
  .veriationSection {
    display: flex;
    align-items: center;
    gap: 12px;
    background: #f6f6f6;
    padding: 8px 15px 8px 10px;
    border-top-left-radius: 20px;
  }
  /* .deleteIcon {
    cursor: pointer;
    @media (max-width: 767px) {
      display: none;
    }
  } */
  .threeDotIcon {
    cursor: pointer;
    /* display: none;
    @media (max-width: 767px) {
      display: block;
    } */
  }
`;

export const ButtonStyle = styled.button`
  background: ${({ btnStyle, active }) =>
    active ? "#3a7bc2" : btnStyle?.background};
  color: ${({ btnStyle, active }) => (active ? "#fff" : btnStyle?.color)};
  padding: ${({ btnStyle }) => btnStyle?.padding || "10px 20px"};
  margin: ${({ btnStyle }) => btnStyle?.margin || "0"};
  border-radius: ${({ btnStyle }) => btnStyle?.borderRadius || "15px"};
  border: ${({ btnStyle }) => btnStyle?.border || "1px solid transparent"};
  font-weight: 600;
  cursor: ${({ btnStyle, active }) => (active ? "pointer" : btnStyle?.cursor)};
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ btnStyle }) => btnStyle?.gap || "8px"};

  @media (max-width: 767px) {
    display: ${({ notShow }) => (notShow ? "none" : "flex")};
  }
`;

const TextAreaStyle = styled.textarea`
  width: 100%;
  height: ${({ height }) => height || "calc(100vh - 380px)"};
  resize: none;
  padding: 15px 20px;
  border: 1px solid #c3c5c9;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a0a4ab;
    border-radius: 8px;
    border: 2px solid #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #8e9299;
  }

  &:focus {
    outline: none;
  }
`;

const VersionStyleTab = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 16px;
  color: #7d7d7d;

  button {
    width: 60px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: default;

    background: #d0cdcd;
    border-radius: 100%;
    height: 32px;
    width: 32px;
  }

  .summaryTitle {
    font-weight: 500;
    span {
      color: #000;
      font-size: 24px;
    }
  }
`;

const PopContent = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 16px;
  font-weight: 700;
  > p {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    cursor: pointer;
  }

  .hideInMobile {
    display: none;
    @media (max-width: 767px) {
      display: flex;
    }
  }
  .disable {
    cursor: no-drop;
    color: gray;
  }
`;
