import React, { useState } from "react";
import { ButtonComp } from "components/ButtonComp";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SignInStep } from "store/slices/SignInSlice";
import { toast } from "react-toastify";
import axios from "axios";
import { Form, Input } from "antd";

export default function InviteCRM() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const location = useLocation();
  const loginCred = useSelector((state) => state?.shared?.LoginSlice?.data);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {

    setLoading(true);
    // let requestPayload = {
    //   tenant_id: values?.tenantid,
    //   client_id: values?.clientid,
    //   client_secret: values?.clientsecret,
    //   app_key: values?.appkey,
    //   workspace_name: location?.state?.teamName,
    //   user_id: loginCred?.id,
    // };

    const apiURL =
      "https://05kcag33oh.execute-api.us-east-1.amazonaws.com/prod/newclient";

    const payload = {
      tenantid: values?.tenantid,
      company_name: location?.state?.teamName,
      workspacename: location?.state?.teamName,
      userid: loginCred?.id,
      username: loginCred?.email,
      client_id: values?.clientid,
      client_secret: values?.clientsecret,
      app_key: values?.appkey,
      app_type: "stmate",
    };

    let res = await axios
      .post(apiURL, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });

    // let res = await createWorkspace(requestPayload);
    if (res?.status === 200 || res?.status === 201) {
      // navigate("/private-layout");
      dispatch(SignInStep({ ...loginCred, token: "token" }));
      setLoading(false);
    } else {
      toast.error(
        res?.data ||
          res?.response?.data?.message ||
          res?.message ||
          res ||
          "Something went wrong"
      );
      setLoading(false);
    }
  };

  const FieldValues = [
    {
      label: "Client ID",
      name: "clientid",
      type: "text",
      placeholder: "Enter client ID",
      message: "Client ID is required",
    },
    {
      label: "Client Secret",
      name: "clientsecret",
      type: "text",
      placeholder: "Enter client secret",
      message: "Client Secret is required",
    },

    {
      label: "Tenant ID",
      name: "tenantid",
      type: "text",
      placeholder: "Enter tenant ID",
      message: "Tenant ID is required",
    },

    {
      label: "App Key",
      name: "appkey",
      type: "text",
      placeholder: "Enter app Key",
      message: "App Key is required",
    },
  ];

  return (
    <div className="w-full h-full flex items-center">
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        autoComplete="off"
        className="w-full"
      >
        <div>
          <p className="text-sm font-bold">STEP 2 OF 2</p>
        </div>
        <div className="text-3xl font-bold mt-3">Connect CRM</div>
        <div className="my-6">
          {FieldValues &&
            FieldValues?.map((field) => (
              <div>
                <label className="font-bold text-sm">{field?.label}</label>
                <Form.Item
                  name={field?.name}
                  rules={[
                    {
                      required: true,
                      message: field?.message,
                    },
                  ]}
                  className="mt-[6px]"
                >
                  <Input
                    placeholder={field?.placeholder}
                    className="w-full h-[52px] bg-white rounded-lg py-[14px] px-4 text-sm border-none custom-shadow mt-2"
                  />
                </Form.Item>
              </div>
            ))}
        </div>
        <div>
          <ButtonComp textcard="Connect" isLoading={loading} />
        </div>
      </Form>
    </div>
  );
}
