import { getPromptLogListing } from "services/Collections";
import { Modal, Table, Tooltip, DatePicker, Spin } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MdContentCopy } from "react-icons/md";
import moment from "moment/moment";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

const PromptLogComp = () => {
  const loginData = useSelector((state) => state?.shared?.LoginSlice?.data);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(1);

  const [text, setText] = useState("");
  const [copied, setCopied] = useState(false);
  const [date, setDate] = useState([
    moment().subtract(7, "days").format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setText("");
    setCopied(false);
  };

  const handlePagination = (page, limit) => {
    setCount(page);
    handleLogListing(page, limit);
  };

  const copyText = (value) => {
    navigator.clipboard.writeText(value);
  };

  const handleRangePicker = (_, dateString) => {
    setCount(1);
    if (dateString[0]?.length > 0) {
      setDate(dateString);
    } else {
      setDate([
        moment().subtract(7, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ]);
    }
  };

  const handleLogListing = async (page = 1, limit = 20) => {
    setLoading(true);
    let key = loginData?.workspaces?.[0]?.key;

    let param = new URLSearchParams();
    param.append("date_start", date?.[0]);
    param.append("date_end", date?.[1]);
    param.append("page", page);
    param.append("page_size", limit);

    let res = await getPromptLogListing(key, param);
    if (res?.status === 200) {
      let updatedData = res?.data?.map((el, index) => ({
        ...el,
        key: index + 1,
        email: el?.estimate?.user,
        job: el?.estimate?.job,
      }));
      setLogs(updatedData || []);
      setTotal(res?.total || 0);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
    }
  };

  useEffect(() => {
    handleLogListing();
  }, [date]);

  useEffect(() => {
    if (text) showModal();
  }, [text]);

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 220,
      render: (text) => {
        return text ? (
          <div>{text}</div>
        ) : (
          <div style={{ textAlign: "center" }}>N/A</div>
        );
      },
    },
    {
      title: "Job No.",
      dataIndex: "job",
      key: "job",
      width: 180,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} trigger="hover" placement="topLeft">
          {text}
        </Tooltip>
      ),
    },
    {
      title: "User Prompt",
      dataIndex: "input",
      key: "input",
      render: (val) => {
        let res = JSON.stringify(val);
        return (
          <p
            className="summary"
            onClick={() => setText({ res, title: "User Prompt" })}
          >
            {res}
          </p>
        );
      },
      ellipsis: true,
    },
    {
      title: "User Prompt Response",
      dataIndex: "ai_response",
      key: "ai_response",
      render: (val) => {
        let res = typeof val === "string" ? val : JSON.stringify(val);
        return (
          <p
            className="summary"
            onClick={() => setText({ res, title: "User Prompt Response" })}
          >
            {res}
          </p>
        );
      },
      ellipsis: true,
    },
    {
      title: "System Prompt",
      dataIndex: "prompt",
      key: "prompt",
      render: (val) => {
        let res = typeof val === "string" ? val : JSON.stringify(val);
        return (
          <p
            className="summary"
            onClick={() => setText({ res, title: "System Prompt" })}
          >
            {res}
          </p>
        );
      },
      ellipsis: true,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 120,
      render: (date) => {
        return moment(date).format("DD/MM/YYYY");
      },
    },
  ];

  return (
    <PromptLogWrapper>
      <div className="headerWrapper">
        <h1 className="header">Logs</h1>
        <RangePicker
          value={[dayjs(date[0]), dayjs(date[1])]}
          onChange={handleRangePicker}
        />
      </div>
      {loading ? (
        <div className="loadingWrapper">
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 48,
                  color: "gray",
                }}
                spin
              />
            }
          />
        </div>
      ) : (
        <div className="tableWrapper">
          <Table
            columns={columns}
            dataSource={logs}
            pagination={{
              current: count,
              total: total,
              pageSize: 20,
              // hideOnSinglePage: true,
              onChange: handlePagination,
            }}
            scroll={{
              x: "100%",
              y: "calc(100vh - 300px)",
            }}
          />
        </div>
      )}
      <Modal
        style={{ top: "30px" }}
        prefixCls="reWrite"
        open={isModalOpen}
        footer={false}
        onCancel={handleCancel}
      >
        <TitleWrapper>
          {text?.title}{" "}
          <MdContentCopy
            color={copied ? "green" : "black"}
            onClick={() => {
              copyText(text?.res);
              !copied && setCopied(true);
            }}
          />
        </TitleWrapper>
        <p>{text?.res}</p>
      </Modal>
    </PromptLogWrapper>
  );
};

export default PromptLogComp;

const PromptLogWrapper = styled.div`
  padding: 20px 20px;
  width: 100%;
  height: calc(100vh - 50px);
  overflow: auto;

  .loadingWrapper {
    height: calc(100vh - 300px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .header {
      font-size: 26px;
      font-weight: 600;
    }
  }

  .tableWrapper {
    padding: 10px;
    background: white;
    border-radius: 10px;

    .summary {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 20px;

  svg {
    cursor: pointer;
  }
`;
