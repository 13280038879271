import React, { useState } from "react";
import styled from "styled-components";
import { FaAngleDown } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { FaAngleUp } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { IoEyeOutline } from "react-icons/io5";
import { Popover } from "antd";
import moment from "moment";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import { CalendarOutlined } from "@ant-design/icons";

const formatDate = (date) => {
  const today = moment();
  const inputDate = moment(date);

  if (today.isSame(inputDate, "day")) {
    return "Today";
  } else {
    return inputDate.format("ddd MMM D");
  }
};

const JobsTabComp = ({
  type,
  data,
  moveToPast,
  movetoActive,
  onEdit,
  singleEstimate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const displayInfo = data?.estimates?.[data?.estimates?.length - 1];
  const [pop, setPop] = useState(false);

  const GetTitle = (version) => {
    let aiSummary = version?.ai_response
      ?.split("***")
      ?.filter((ele) => ele?.trim()?.length > 1 && ele);
    const aiTitle =
      (aiSummary?.length > 1 && aiSummary?.[aiSummary?.length - 1]) || "";

    return aiTitle;
  };

  const content = (
    <ContnetWrapper>
      <div
        onClick={() => {
          onEdit();
          setPop(!pop);
        }}
      >
        <FiEdit size="20px" /> Edit
      </div>
      <div
        onClick={() => {
          setIsOpen(!isOpen);
          setPop(!pop);
        }}
      >
        <IoEyeOutline size="22px" /> See Details
      </div>
      {type === "Past" ? (
        <div
          onClick={() => {
            movetoActive();
            setPop(!pop);
          }}
        >
          <IoArrowUpCircleOutline size="24px" /> Move To Active
        </div>
      ) : (
        <div
          onClick={() => {
            moveToPast();
            setPop(!pop);
          }}
        >
          <CalendarOutlined style={{ fontSize: "18px" }} /> Move To Past
        </div>
      )}
    </ContnetWrapper>
  );

  return (
    <JobsTabs isOpen={isOpen} len={data?.estimates?.length > 3}>
      <div className="left">
        <i onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <FaAngleUp /> : <FaAngleDown />}
        </i>
        <div className="leftHeader">
          <div className="userDetail">
            <p className="name">{displayInfo?.title}</p>
            <p className="time">{formatDate(displayInfo?.created_at)}</p>
          </div>

          <div className="actions">
            <button className="edit" onClick={onEdit}>
              <FiEdit />
              Edit
            </button>
            {type === "Past" ? (
              <div className="moveToPast" onClick={movetoActive}>
                <IoArrowUpCircleOutline size="20px" /> Move To Active
              </div>
            ) : (
              <div className="moveToPast" onClick={moveToPast}>
                <CalendarOutlined /> Move To Past
              </div>
            )}
          </div>
          <a
            href={
              process.env.REACT_APP_JOB_URL +
              "v1/jobs/" +
              data?.job?.encoded_job
            }
            target="_blank"
            rel="noreferrer"
            className="serviceTitan"
          >
            Service Titan Job# {data?.job?.number} <BsBoxArrowUpRight />
          </a>
        </div>
      </div>

      <div className="right">
        <div className="rightHeader">
          <div className="rightHeaderFirst">
            <div className="tagsWrapper">
              {data?.estimates?.map((ele, i) => (
                <TagStyle
                  key={i}
                  active={ele?.versions?.some((e) => e?.pushed_status)}
                  onClick={() => singleEstimate(data, i)}
                >
                  {i + 1}
                </TagStyle>
              ))}
            </div>
            <Popover
              content={content}
              placement="bottomLeft"
              trigger="click"
              prefixCls="tag"
              className={isOpen && "popOver"}
              open={pop}
              onOpenChange={(open) => {
                setPop(open);
              }}
            >
              <i>
                <HiDotsVertical size="25px" onClick={() => setPop(!pop)} />
              </i>
            </Popover>
          </div>

          <div className="contentWrapper">
            {data?.estimates?.map((ele, i) => (
              <div
                className="content"
                key={i}
                onClick={() => singleEstimate(data, i)}
              >
                <TagStyle active={ele?.versions?.some((e) => e?.pushed_status)}>
                  {i + 1}
                </TagStyle>{" "}
                <p>{GetTitle(ele?.versions?.[0])}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </JobsTabs>
  );
};

export default JobsTabComp;

const JobsTabs = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 6px;
  gap: 12px;
  overflow: hidden;

  .popOver {
    width: 0;
    max-height: 0;
    transition: opacity 0.5s, max-height 2s;
    pointer-events: none;
    overflow: hidden;
  }

  .left {
    width: 100%;
    display: flex;
    gap: 20px;

    i {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: fit-content;
      height: 50px;
    }

    .leftHeader {
      display: flex;
      flex-direction: column;
      gap: ${({ isOpen }) => (isOpen ? "15px" : "0")};
      transition: gap 300ms;

      .userDetail {
        .name {
          font-weight: 600;
          font-size: 17px;
        }
        .time {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .actions {
      display: flex;
      gap: 15px;
      align-items: center;
      opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
      height: ${({ isOpen }) => (isOpen ? "32px" : 0)};
      transition: opacity 300ms, height 300ms;
      overflow: hidden;

      .edit {
        padding: 5px 10px;
        background: #000;
        color: #fff;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-size: 14px;
      }
      .moveToPast {
        padding: 5px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        border-radius: 8px;
        background: #e7e7e7;
        cursor: pointer;
      }
    }

    .serviceTitan {
      height: ${({ isOpen }) => (isOpen ? "25px" : 0)};
      display: flex;
      align-items: baseline;
      gap: 8px;
      color: #4096ff;
      opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
      transition: opacity 300ms, height 300ms;
    }
  }
  .right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: ${({ isOpen }) => (isOpen ? "unset" : "end")};

    .rightHeader {
      display: flex;
      flex-direction: column;

      .rightHeaderFirst {
        display: flex;
        gap: 10px;
        justify-content: end;
        height: ${({ isOpen }) => (isOpen ? 0 : "fit-content")};
      }

      .tagsWrapper {
        margin-top: 12.5px;
        display: flex;
        align-items: center;
        gap: 5px;
        flex-wrap: wrap;
        justify-content: end;
        opacity: ${({ isOpen }) => (isOpen ? 0 : 1)};
      }

      i {
        margin-top: 12.5px;
        cursor: pointer;
      }

      .contentWrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
        transition: height 400ms, opacity 400ms;
        height: ${({ isOpen, len }) =>
          isOpen ? (len ? "270px" : "200px") : 0};
        opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
        overflow: auto;

        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #a0a4ab;
          border-radius: 8px;
          border: 2px solid #f1f1f1;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: #8e9299;
        }

        &:focus {
          outline: none;
        }

        .content {
          display: flex;
          gap: 5px;
          align-items: center;
          border-bottom: 1px solid #b9b9b994;
          padding: 10px 0;
          width: 100%;
          cursor: pointer;

          p {
            width: calc(100% - 25px);
          }
        }
      }
    }
  }
`;

const TagStyle = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ active }) => (active ? "#fff" : "#000")};
  background: ${({ active }) => (active ? "#000" : "#dfdfdf")};
  border-radius: 100%;
  font-size: 12px;
  border: none;
  font-weight: 700;
  cursor: pointer;
`;

const ContnetWrapper = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  > div {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: start;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
`;
