import { Modal, Switch } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { InputTextAreaStyle } from "./ReWriteSummary";

const MakeUpdate = ({ name, setName, open, close, submit }) => {
  const inputPackageNameRef = useRef(null);
  const [switchBtn, setSwitchBtn] = useState(false);

  const onChange = (checked) => {
    setSwitchBtn(checked);
  };

  const adjustHeight = () => {
    const textarea = inputPackageNameRef.current;
    if (textarea) {
      inputPackageNameRef?.current?.focus();
    }
  };

  const handleInput = (event) => {
    setName(event.target.value);
    adjustHeight();
  };

  useEffect(() => {
    adjustHeight();
  }, []);

  return (
    <Modal
      style={{ top: "30px" }}
      width={"700px"}
      prefixCls="reWrite"
      open={open}
      title="Make Updates"
      footer={false}
      onCancel={close}
    >
      <MakeUpdateStyle>
        <p>
          Add instructions for STMate to change, or add, or remove parts of this
          estimate.
        </p>
        <div className="inputBorder">
          <InputTextAreaStyle
            ref={inputPackageNameRef}
            placeholder="Add instructions"
            type="text"
            value={name}
            rows={1}
            onInput={handleInput}
            style={{
              overflow: "auto",
              resize: "none",
              width: "100%",
              maxHeight: "100px",
            }}
          />
        </div>

        <div className="bottomWrapper">
          <div className="switchWrap">
            <Switch onChange={onChange} /> Save as a new estimate
          </div>

          <div className="footer">
            <button onClick={close}>Cancel</button>
            <button
              style={{ color: name?.trim()?.length > 0 ? "#000" : "#9d9b9b" }}
              onClick={()=>submit(switchBtn)}
            >
              Submit
            </button>
          </div>
        </div>
      </MakeUpdateStyle>
    </Modal>
  );
};

export default MakeUpdate;

const MakeUpdateStyle = styled.div`
  .inputBorder {
    border: 1px solid gray;
    width: 100%;
    height: fit-content;
    border-radius: 8px;
    margin: 20px 0;
    padding: 0;
  }

  .footer {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px;
    button {
      padding: 10px 20px;
      background: #e9e6e6;
      border-radius: 15px;
      box-shadow: 0px 2px 2px #c0bebe;
      color: black;
      font-weight: 600;
    }
  }

  .bottomWrapper {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
      display: block;
    }
  }

  .switchWrap {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }
`;
