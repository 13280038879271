import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Loader } from "../../components/Loader";
import {
  BillingSearchIcon,
  ProfileDefaultIconlight,
} from "../../utils/Icons/SvgIcons";
import { Modal } from "antd";
import { assignManager, getManagers } from "../../services/Collections";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export const AssignManagerModal = ({ open, handleClose, data, listingApi }) => {
  const [values, setvalues] = useState(Number(data?.manager?.id));
  const usersDetails = useSelector((state) => state?.shared?.LoginSlice?.data);
  let key = usersDetails?.workspaces?.[0]?.key;
  const [loading, setLoading] = useState(false);
  const [sumbitLoading, setSubmitLoading] = useState(false);
  const [managerList, setManagerList] = useState([]);
  const [searchdata, setSeatchdata] = useState([]);
  const [searchWord, setSearchWord] = useState("");

  const handleManagerListing = async () => {
    setLoading(true);
    let key = usersDetails?.workspaces?.[0]?.key;
    let res = await getManagers(key);
    if (res?.status === 200 || res?.status === 201) {
      setManagerList(res?.data);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
    }
  };

  const searchManager = (word) => {
    setSearchWord(word);
    if (word) {
      let updated = managerList?.filter((el) =>
        el?.name?.toLowerCase()?.includes(word.toLowerCase())
      );
      setSeatchdata(updated);
    } else {
      setSeatchdata([]);
    }
  };

  const handleSubmit = async () => {
    if (values) {
      setSubmitLoading(true);
      let requestPayload = {
        manager_id: values,
      };
      let key = usersDetails?.workspaces?.[0]?.key;
      let res = await assignManager(key, data?.id, requestPayload);
      if (res?.status === 200 || res?.status === 201) {
        setSubmitLoading(false);
        if (data?.manager?.id) {
          toast.success("Manager Updated Successfully");
        } else {
          toast.success("Manager Assigned Successfully");
        }
        handleClose();
        listingApi();
      } else {
        setSubmitLoading(false);
        toast.error(
          res?.response?.data?.message || res?.message || "Something went wrong"
        );
      }
    } else {
      toast.warning("Please select the manager");
    }
  };

  useEffect(() => {
    handleManagerListing();
  }, []);

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      footer={false}
      prefixCls="assign-modal"
      centered
    >
      {/* <ModalWrapper>
        <div className="title">
          <h2>Assign Manager</h2>
          <p>Assign Manager to {data?.name}</p>
        </div>

        <div className="content">
          <div className="search-box">
            <input />
          </div>
          <div className="managers-list">
            <div className="list">
              <div className="name">
                <p>1.</p>
                <p>Name</p>
              </div>
            </div>
          </div>
        </div>
      </ModalWrapper> */}
      <AssignManagerWrapper>
        {!loading ? (
          <>
            <div className="title">
              <h3>{data?.manager?.id ? "Edit" : "Assign"} manager</h3>
              <p>
                {data?.manager?.id ? "Edit" : "Assign"} manager{" "}
                {data?.manager?.id ? "for" : "to a"} {data?.name}
              </p>
            </div>
            <div className="searchSection">
              <input
                placeholder="Search"
                type="text"
                className="input"
                onChange={(e) => searchManager(e.target.value)}
                style={{ fontWeight: searchdata ? "700" : "" }}
              />
              <div className="search-icon">
                <BillingSearchIcon />
              </div>
            </div>

            <section>
              {searchdata.length > 0 || searchWord ? (
                <>
                  {searchdata?.length > 0 ? (
                    searchdata?.map((ele, idx) => (
                      <div key={idx} className="manager-listing">
                        <div className="radio-flex">
                          <input
                            checked={ele?.id === values}
                            type="radio"
                            name="manager"
                            onClick={() =>
                              setvalues(values === ele?.id ? "" : ele?.id)
                            }
                          />
                        </div>
                        <div className="img-flex">
                          <span className="light-profile-icon">
                            <ProfileDefaultIconlight />
                          </span>

                          <h3>{ele?.name ? ele?.name : "---"}</h3>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-manager">
                      <p>No Search found</p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {managerList?.length > 0 ? (
                    managerList?.map((ele, idx) => (
                      <div key={idx} className="manager-listing">
                        <div className="radio-flex">
                          <input
                            defaultChecked={ele?.id == data?.manager?.id}
                            checked={ele?.id === values}
                            type="radio"
                            name="manager"
                            onClick={() =>
                              setvalues(values === ele?.id ? "" : ele?.id)
                            }
                          />
                        </div>
                        <div className="img-flex">
                          <span className="light-profile-icon">
                            <ProfileDefaultIconlight />
                          </span>

                          <h3>{ele?.name ? ele?.name : "---"}</h3>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-manager">
                      <p>No manager assigned yet</p>
                    </div>
                  )}
                </>
              )}
            </section>

            <div className="btn-wrapper">
              <button onClick={handleClose}>Cancel</button>

              {sumbitLoading ? (
                <button className="btn-save">Loading...</button>
              ) : (
                <button className="btn-save" onClick={handleSubmit}>
                  {data?.manager?.id ? "Update" : "Save"}
                </button>
              )}
            </div>
          </>
        ) : (
          <div className="loader-box">
            <Loader size={30} color={"#416289"} />
          </div>
        )}
      </AssignManagerWrapper>
    </Modal>
  );
};

const AssignManagerWrapper = styled.div`
  width: 100%;
  padding: 25px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 8px;

  .searchSection {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    gap: 4px;
    width: 100%;
    position: relative;
    margin-top: 32px;

    @media (max-width: 960px) {
      max-width: 100%;
    }

    .search-icon {
      position: absolute;
      right: 22px;

      @media (max-width: 960px) {
        bottom: 12px;
      }

      svg {
        filter: ${({ themeValue }) =>
          themeValue == "day" ? "invert(0)" : "invert(1)"};
      }
    }

    .input {
      color: #000 !important;
      padding: 16px 20px;
      font-family: "Lato";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      height: 54px;
      line-height: 160%;
      border-radius: 10px;
      border: 1px solid #939aa3;
      background: #fff;

      :focus {
        outline: none;
        border: 1px solid #00a1e6 !important;
        font-weight: 700;
      }
      :hover {
        border: 1px solid #000;
      }
      ::placeholder {
        color: #424b57 !important;
      }
    }

    input::placeholder {
      color: #424b57;
      font-family: "Lato";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
    }
  }

  .loader-box {
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    width: 100%;

    h3 {
      color: #000;
      font-family: "Lato";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
    }
    p {
      color: #000;
      /* Body/medium/regular */
      font-family: "Lato";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      margin-top: 10px;
    }
  }

  section {
    margin-top: 16px;
    overflow-y: auto;
    height: 300px;
    padding: 0 10px 0 0;

    &::-webkit-scrollbar {
      width: 3px;
      height: 2px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
    }
    &::-webkit-scrollbar-thumb {
      background: #cecece;
      border-radius: 10px;
    }

    .manager-listing {
      width: 100%;
      display: flex;
      gap: 8px;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 16px 0;

      .img-flex {
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
        h3 {
          color: #000;
          text-transform: capitalize;
          font-family: "Lato";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 22.4px;
          letter-spacing: 0.2px;
        }
        .light-profile-icon {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background: #191c21;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 18px;
            height: 18px;
            filter: invert(1);
          }
        }
      }
      .radio-flex {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        input {
          width: 24px;
          height: 24px;
          ${"" /* accent-color: #000000; */}
        }
      }
    }
  }

  .no-manager {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 53px;

    img {
      height: 188px;
      width: 188px;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #101010;
      margin-top: 23px;
    }
  }

  .btn-wrapper {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 30px;

    @media (max-width: 480px) {
      flex-direction: column-reverse;
      gap: 10px;
    }

    button {
      width: 100%;
      display: flex;
      height: 48px;
      padding: 21px 24px;
      justify-content: center;
      align-items: center;
      background: #fff;
      gap: 8px;
      border-radius: 10px;
      border: 1px solid #000;
      color: #000;
      text-align: center;
      cursor: pointer;
      font-family: "Lato";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: 0.2px;
    }

    .btn-save {
      background: #012e61;
      color: #fff;
    }
  }
`;

export const ProfileAvatar = styled.div`
  .avatar-name {
    display: flex;
    align-items: center;
    gap: 10px;

    .avatar {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background: rgb(0 161 230 / 36%);
      color: #00a1e6;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
