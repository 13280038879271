import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { ButtonComp } from "./ButtonComp";
import { useLocation, useNavigate } from "react-router-dom";
import { Check, Dot } from "utils/Icons/SvgIcons";
import { styled } from "styled-components";

const AuthForm = ({ loading, onFinish, data, setCheckStrongPass }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const [passed, setPassed] = useState({
    min8: false,
    lowercase: false,
    uppercase: false,
    special: false,
    numbers: false,
    // consecutive: false
  });

  const validatePassword = (password) => {
    if (password.length > 0) {
      if (password.length >= 8) {
        setPassed((prevState) => ({
          ...prevState,
          min8: true,
        }));
      } else {
        setPassed((prevState) => ({
          ...prevState,
          min8: false,
        }));
      }
      if (password.search(/[a-z]/) !== -1) {
        setPassed((prevState) => ({
          ...prevState,
          lowercase: true,
        }));
      } else {
        setPassed((prevState) => ({
          ...prevState,
          lowercase: false,
        }));
      }
      if (password.search(/[A-Z]/) !== -1) {
        setPassed((prevState) => ({
          ...prevState,
          uppercase: true,
        }));
      } else {
        setPassed((prevState) => ({
          ...prevState,
          uppercase: false,
        }));
      }
      if (password.search(/[0-9]/) !== -1) {
        setPassed((prevState) => ({
          ...prevState,
          numbers: true,
        }));
      } else {
        setPassed((prevState) => ({
          ...prevState,
          numbers: false,
        }));
      }
      if (password.search(/[^\w]/) !== -1) {
        setPassed((prevState) => ({
          ...prevState,
          special: true,
        }));
      } else {
        setPassed((prevState) => ({
          ...prevState,
          special: false,
        }));
      }
    } else {
      setPassed({
        min8: false,
        lowercase: false,
        uppercase: false,
        special: false,
        numbers: false,
        // consecutive: false
      });
    }
  };

  useEffect(() => {
    if (
      passed?.min8 &&
      passed?.lowercase &&
      passed?.uppercase &&
      passed?.special &&
      passed?.numbers
    ) {
      setCheckStrongPass && setCheckStrongPass(true);
    } else {
      setCheckStrongPass && setCheckStrongPass(false);
    }
  }, [passed]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      className={`w-[100%] md:w-[372px] 2xl:w-[70%] grid ${
        location?.pathname?.includes("forgot-password") ? "gap-4" : "gap-10"
      }`}
    >
      <div>
        <h2 className="text-4xl font-bold my-3">{data?.header}</h2>
        <p className="text-base text-[#b8b8b8]">{data?.subHeader}</p>
      </div>
      <div>
        {data?.email && (
          <div>
            <label className="text-base font-bold">Email</label>
            <Form.Item
              name="email"
              rules={[
                {
                  // Custom validator to check email domain
                  validator: (_, value) => {
                    const allowedDomains = [
                      "adleverage.com",
                      "trioheatingandair.com",
                    ];
                    if (value === "" || !value) {
                      return Promise.reject("Please input your E-mail!");
                    } else {
                      const emailDomain = value.split("@")[1]; // Extract domain from email
                      if (allowedDomains.includes(emailDomain)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Oops! It looks like the domain you entered isn't allowed."
                        );
                      }
                    }
                  },
                },
              ]}
            >
              <Input
                placeholder="Enter your email address"
                className="w-full h-[52px] bg-white rounded-lg py-[14px] px-4 text-base border-none custom-shadow mt-2"
              />
            </Form.Item>
          </div>
        )}
        {data?.oldPassword && (
          <div>
            <label className="text-base font-bold">Current Password</label>
            <Form.Item
              name="old_password"
              className="mb-2"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                placeholder="Enter your old password"
                className="w-full h-[52px] bg-white rounded-lg py-[8px] px-3 text-base border-none custom-shadow mt-2"
                autocomplete="off"
                onChange={(e) => {
                  form.setFieldValue("old_password", e.target.value);
                }}
              />
            </Form.Item>
          </div>
        )} 
        {data?.password && (
          <div className="mt-6">
            <label className="text-base font-bold">
              {location?.pathname?.includes("set-new-password") ||
              location?.pathname?.includes("change-password")
                ? "New Password"
                : "Password"}
            </label>
            <Form.Item
              name="password"
              className="mb-2"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                placeholder="Enter your password"
                className="w-full h-[52px] bg-white rounded-lg py-[8px] px-3 text-base border-none custom-shadow mt-2"
                autocomplete="off"
                onChange={(e) => {
                  form.setFieldValue("password", e.target.value);
                  validatePassword(e.target.value);
                }}
              />
            </Form.Item>
          </div>
        )}
        {data?.confirmPassword && (
          <>
            <div className="mt-6">
              <label className="text-base font-bold">
                {location?.pathname?.includes("set-new-password") ||
                location?.pathname?.includes("change-password")
                  ? "Confirm New Password"
                  : "Confirm Password"}
              </label>
              <Form.Item
                className="mb-2"
                name="confirm"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The new password that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Enter confirm password"
                  className="w-full h-[52px] bg-white rounded-lg py-[14px] px-4 text-base border-none custom-shadow mt-2"
                />
              </Form.Item>
            </div>
            <PasswordRequirement>
              <p>Password requirements :</p>
              <div>
                <ul className="password-validations">
                  <li className={passed?.min8 && "active"}>
                    {" "}
                    {passed?.min8 ? <Check /> : <Dot />} Min. 8 characters
                  </li>
                  <li className={passed?.lowercase && "active"}>
                    {" "}
                    {passed?.lowercase ? <Check /> : <Dot />} Include lowercase
                    characters
                  </li>
                  <li className={passed?.uppercase && "active"}>
                    {" "}
                    {passed?.uppercase ? <Check /> : <Dot />} Include uppercase
                    characters
                  </li>
                  <li className={passed?.special && "active"}>
                    {" "}
                    {passed?.special ? <Check /> : <Dot />} Include special
                    characters
                  </li>

                  <li className={passed?.numbers && "active"}>
                    {" "}
                    {passed?.numbers ? <Check /> : <Dot />} Include numbers
                  </li>
                </ul>
              </div>
            </PasswordRequirement>
          </>
        )}
        {data?.forgotPassword && (
          <div
            className="w-full text-primary-theme text-base cursor-pointer text-right"
            onClick={() => navigate("/forgot-password")}
          >
            Forgot Password
          </div>
        )}
      </div>
      <div>
        <ButtonComp
          textcard={data?.buttonText}
          isLoading={loading}
          // handleAction={() => navigate("/congratulation")}
        />
      </div>
      {(data?.footer || data?.subFooter) && (
        <footer className="text-center text-base font-bold mb-4">
          {data?.footer}{" "}
          {data?.subFooter && (
            <span
              className={`text-primary-theme font-bold cursor-pointer ${
                data?.subFooter != "Back" && "underline"
              }`}
              onClick={() => navigate(data?.footerRoute)}
            >
              {data?.subFooter}
            </span>
          )}
        </footer>
      )}
    </Form>
  );
};

export default AuthForm;

export const PasswordRequirement = styled.div`
  width: 100%;
  p {
    font-family: Lato, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    margin: 16px 0;
    color: #000;
  }
  div {
    display: flex;
    justify-content: space-between;
    .password-validations {
      list-style: none;
      font-family: Lato, sans-serif;
      font-family: Lato, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: -0.01em;
      background: transparent;

      li {
        display: flex;
        align-items: center;
        gap: 5px;
        > svg {
          margin: 0 !important;
          filter: none;
        }
      }
    }
    .active {
      color: #22b85f !important;
    }

    @media (max-width: 1245px) {
      flex-direction: column !important;
    }
  }
`;
