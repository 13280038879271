import React, { useEffect, useState } from "react";
import AppRoutes from "./routes";
import "./App.css";
import { useDispatch } from "react-redux";
import {
  resetStmateSlice,
  updateHeader,
  updateOutsideJobLink,
} from "store/slices/StmateSlice";
import { useSelector } from "react-redux";
import { styled } from "styled-components";
import { Loader } from "components/Loader";
import useBroadcastChannel from "components/hook/useBroadcastChannel ";
import { store } from "store/store";

function App() {
  const dispatch = useDispatch();
  const currentWindow = window?.location?.pathname;
  const headerData = useSelector((state) => state?.tabSpecific?.STMate?.header);
  const [load, setLoad] = useState(false);

  useBroadcastChannel(store);

  useEffect(() => {
    if (currentWindow) {
      const parts = currentWindow?.split("/jobs/");
      if (parts.length > 1) {
        const afterJobs = parts[1];
        if (headerData?.currentJob && headerData?.currentJob !== afterJobs) {
          dispatch(updateHeader(null));
          dispatch(resetStmateSlice(true));
        }
        dispatch(updateOutsideJobLink(afterJobs));
      }
    }
    setLoad(true);
  }, []);

  if (!load) {
    return (
      <LoaderWrapper>
        <Loader size="24px" color="#000" />
      </LoaderWrapper>
    );
  }

  return <AppRoutes />;
}

export default App;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;
