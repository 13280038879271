import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  sideNav: false,
  notificationTab: 1,
  rememberMe: null,
  verifyOtp: null,
  steps: 0,
}

export const SignInSlice = createSlice({
  name: "SignInSlice",
  initialState: initialState,
  reducers: {
    SignInStep: (state, actions) => {
      if (actions) {
        state.data = actions.payload;
      }
    },
    OpenSideNav: (state, action) => {
      if (action) {
        state.sideNav = action.payload;
      }
    },
    UpdateSteps: (state, action) => {
      if (action) {
        state.steps = action?.payload;
      }
    },
    SigninInitialStateReset: (state, actions) => actions?.payload?.data ? actions?.payload : initialState
  },
});

export const { SignInStep, OpenSideNav, UpdateSteps, SigninInitialStateReset } =
  SignInSlice.actions;
export default SignInSlice.reducer;
