import AuthForm from "components/AuthForm";
import React, { useState } from "react";
import { CreateAccountData } from "./data";
import { createAccount, sendOtp } from "../services/Collections";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SignInStep } from "store/slices/SignInSlice";
import { useDispatch } from "react-redux";

const CreateAccount = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checkStrongPass, setCheckStrongPass] = useState(false);

  const handleSendOtp = async (email) => {
    let requestPayload = {
      email: email,
    };
    let res = await sendOtp(requestPayload);
    if (res?.status === 200 || res?.status === 201) {
      toast.success("OTP sent to your email");
    } else {
      // toast.error(
      //   res?.response?.data?.message || res?.message || "Something went wrong"
      // );
    }
  };

  const handleSubmit = async (values) => {
    if (!checkStrongPass) {
      return;
    }
    setLoading(true);
    let requestPayload = {
      email: values?.email,
      password: values?.password,
      active: 1,
    };
    let res = await createAccount(requestPayload);
    if (res?.status === 200 || res?.status === 201) {
      // toast.success("Account created successfully");
      await handleSendOtp(values?.email);
      setLoading(false);
      dispatch(SignInStep(res?.data));
      navigate("/congratulation", {state:{email: values?.email}});
      // dispatch(login(true));
    } else {
      setLoading(false);
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
    }
  };

  return (
    <div className="authFormWrapper md:mt-[60px]">
      <AuthForm
        loading={loading}
        onFinish={handleSubmit}
        data={CreateAccountData}
        setCheckStrongPass={setCheckStrongPass}
      />
    </div>
  );
};

export default CreateAccount;
