import React, { useEffect } from "react";
import SubHeader from "./SubHeader";
import { getIssuesListing } from "services/Collections";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveIssue, updatePastIssue } from "store/slices/StmateSlice";

const Stmate = () => {
  const dispatch = useDispatch();
  const stmateData = useSelector((state) => state?.tabSpecific?.STMate);
  const loginData = useSelector((state) => state?.shared?.LoginSlice?.data);

  const handleActiveListing = async () => {
    let key = loginData?.workspaces?.[0]?.key

    let param = new URLSearchParams();
    stmateData?.outsideJob && param.append("job", stmateData?.outsideJob)
    param.append("user_id", loginData?.id)
    param.append("status", 1)

    let res = await getIssuesListing(key, param);
    if (res?.status === 200) {
      dispatch(updateActiveIssue(res?.data));
    }
  }
  const handlePastListing = async () => {
    let key = loginData?.workspaces?.[0]?.key

    let param = new URLSearchParams();
    stmateData?.outsideJob && param.append("job", stmateData?.outsideJob)
    param.append("user_id", loginData?.id)
    param.append("status", 0)

    let res = await getIssuesListing(key, param);
    if (res?.status === 200) {
      dispatch(updatePastIssue(res?.data));
    }
  }

  useEffect(() => {
    handleActiveListing();
    handlePastListing();
  }, []);

  return (
    <div className="h-[100%] overflow-auto font-inter bg-[#f3f4f6] relative">
      <SubHeader />
    </div>
  );
};

export default Stmate;
