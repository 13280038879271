import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  initalFromUpdate,
  manageForm,
  parentCategory,
  resetAllActivebtns,
  savingRequestPayload,
  savingRequestResponse,
  selectedType,
  updateVersions,
} from "store/slices/StmateSlice";
import { styled } from "styled-components";
import IssuesComp from "./IssuesComp";
import { toast } from "react-toastify";
import { Loader } from "../../components/Loader";
import {
  categoriesIssues,
  getCategories,
  JobtypeBeforeSubmit,
} from "services/Collections";
import {
  cleanObject,
  convertToServiceString,
  deepClone,
  deepEqual,
  findDeepestLevel,
  removeIsActive,
  toggleActiveStatus,
  toggleExpendStatus,
  truncateJSON,
  truncateTo100Words,
} from "utils/Functions";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import SubmitModal from "./SubmitModal";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { PiArrowCircleRightBold } from "react-icons/pi";
import { MdDoNotDisturb } from "react-icons/md";
import { Button } from "components/SubIssuesComp";

const SubHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    type: stmateData,
    parentCategory: pCat,
    newdata: formData,
  } = useSelector(({ tabSpecific }) => tabSpecific?.STMate);
  const stmateDataa = useSelector((state) => state?.tabSpecific?.STMate);
  const { type, newdata, outsideJob, requestResponse, requestPayload } =
    stmateDataa;
  const workspace = useSelector(
    (state) => state?.shared?.LoginSlice?.data?.workspaces?.[0]
  );
  const userData = useSelector((state) => state?.shared?.LoginSlice?.data);
  const headerData = useSelector((state) => state?.tabSpecific?.STMate?.header);

  const [selectedService, setSelectedService] = useState(stmateData);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [header, setHeader] = useState(false);
  const [divHeight, setDivHeight] = useState(92);
  const observedDivRef = useRef(null);

  const handleReset = () => {
    setSelectedService([]);
    dispatch(resetAllActivebtns());
  };

  const handleCheckboxChange = (event) => {
    if (formData[event?.target?.name]) {
      const newData = toggleActiveStatus(formData, event?.target?.name);
      dispatch(initalFromUpdate(newData));
    } else {
      const clone = deepClone(formData);
      const result = {
        ...clone,
        [event?.target?.name]: { subItems: {}, isActive: true, isExpend: true },
      };
      dispatch(initalFromUpdate(result));
    }

    if (selectedService?.includes(event?.target?.name)) {
      const data = selectedService?.filter(
        (ele) => ele !== event?.target?.name
      );
      setSelectedService(data);
      dispatch(selectedType(data));
    } else {
      setSelectedService((pre) => [...pre, event.target.name]);
      dispatch(selectedType([...selectedService, event.target.name]));
    }

    dispatch(parentCategory(data));
  };

  const handleCategories = async () => {
    setLoading(true);
    const res = await getCategories(workspace?.key);
    if (res?.status === 200) {
      setData(res?.data);
      setLoading(false);
    } else {
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
      setLoading(false);
    }
  };

  const handleDeepestLevel = () => {
    let result = false;
    let modifiedNewData = removeIsActive(newdata);

    if (Object.keys(modifiedNewData)?.length <= 0) {
      result = true;
      return result;
    }

    if (type?.length > 0) {
      for (let index = 0; index < type?.length; index++) {
        if (
          modifiedNewData[type?.[index]] &&
          findDeepestLevel(modifiedNewData[type?.[index]] ?? {}) < 2
        ) {
          result = true;
          break;
        }
      }
    }
    return result;
  };

  const getSystemPrompt = async () => {
    setSubmitLoading(true);

    try {
      let res = await JobtypeBeforeSubmit(
        workspace?.key,
        headerData?.data?.job_number
      );

      console.log("System prompt: ", res?.data?.prompt);
      handleSubmit();
    } catch (error) {
      setSubmitLoading(false);
      console.error("Error fetching system prompt:", error);
    }
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);

    let payload = removeIsActive(newdata);
    let modifiedPayload = cleanObject(payload);

    // if (Object.keys(modifiedNewData[type]?.subItems ?? {})?.length === 0) {
    //   toast.error("Please select categories");
    //   setSubmitLoading(false);
    //   return;
    // } else if (findDeepestLevel(modifiedNewData[type] ?? {}) < 4) {
    //   setSubmitLoading(false);
    //   toast.error("Please select sub categories");
    //   return;
    // }

    if (window.dataLayer) {
      window.dataLayer.push({
        event: "form_submission",
        form_value: truncateJSON(modifiedPayload ?? {}),
        Job_id: outsideJob,
      });
    }

    let reqPayload = {
      job: outsideJob,
      categories: modifiedPayload,
      user_id: userData?.id,
      title: `${headerData?.data?.job_type} || ${headerData?.data?.customer}`,
    };
    if (requestResponse) {
      if (deepEqual(requestPayload?.categories ?? {}, modifiedPayload)) {
        navigate("/v1/stmate-form");
        setSubmitLoading(false);
        console.log("User prompt: ", reqPayload);
        console.log("User prompt response: ", requestResponse);
        return;
      }
    }

    console.log("User prompt: ", reqPayload);
    const res = await categoriesIssues(workspace?.key, reqPayload);

    if (res?.status === 200) {
      if (!res?.data?.ai_response) {
        toast.warning(
          "Oops! information does not exist, Please try with different input"
        );
        setSubmitLoading(false);
        return;
      }
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "form_response",
          form_value: truncateTo100Words(res?.data?.ai_response),
          Job_id: outsideJob,
        });
      }
      console.log("User prompt response: ", res?.data);
      dispatch(savingRequestPayload(reqPayload));
      dispatch(savingRequestResponse(res?.data?.ai_response));
      let apiData = res?.data?.ai_response
        ?.split("***")
        ?.filter((ele) => ele?.trim()?.length > 1 && ele);
      const obj = {
        Summary: apiData?.[0],
        oldSummary: apiData?.[0],
        RecommendedServices: [],
        buttonStatus: "Push",
        title:
          (apiData?.length > 1 && apiData?.[apiData?.length - 1]?.trim()) || "",
        oldTitle:
          (apiData?.length > 1 && apiData?.[apiData?.length - 1]?.trim()) || "",
        isActive: true,
        parentId: res?.data?.id,
        version: res?.data?.versions?.[0]?.id,
      };
      dispatch(manageForm([obj]));
      dispatch(updateVersions([obj]));
      navigate("/v1/stmate-form");
      toast.success(res?.response?.data?.message || res?.message);
      setSubmitLoading(false);
    } else {
      toast.error(
        res?.response?.data?.message || res?.message || "Something went wrong"
      );
      setSubmitLoading(false);
    }
  };

  const handleExpendFun = (val) => {
    const newData = toggleExpendStatus(newdata, val);
    dispatch(initalFromUpdate(newData));
  };

  const filterService = useMemo(() => {
    if (selectedService?.length > 0) {
      let data = Array.from(pCat ?? [])?.filter((e) =>
        selectedService?.includes(e?.name)
      );
      const result = [];
      selectedService?.forEach((ele) => {
        if (data?.find((e) => e.name == ele)) {
          result?.push(data?.find((e) => e.name == ele));
        }
      });
      return result;
    }
  }, [selectedService]);

  useEffect(() => {
    handleCategories();
  }, []);

  useEffect(() => {
    const observedDiv = observedDivRef.current;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { height } = entry.contentRect;
        setDivHeight(height + 20);
      }
    });

    if (observedDiv) {
      resizeObserver.observe(observedDiv);
    }
    return () => {
      if (observedDiv) {
        resizeObserver.unobserve(observedDiv);
      }
    };
  }, [observedDivRef?.current?.clientHeight]);

  return (
    <SubHeaderStyle>
      {openModal && (
        <SubmitModal
          open={openModal}
          setOpen={setOpenModal}
          onOk={handleSubmit}
        />
      )}
      <TestDiv height={header ? "280px" : "40px"}>
        <Header
          jobDetails={headerData?.data}
          header={header}
          setHeader={setHeader}
        />
        {headerData?.data && (
          <div ref={observedDivRef}>
            <div className="md:bg-gray-200 md:p-4">
              <div className="flex items-center justify-between">
                {!loading ? (
                  <div className="bg-[#f3f4f6] md:bg-transparent w-full flex flex-col-reverse md:gap-3 justify-between md:flex-row">
                    <div className="flex md:gap-3 flex-wrap px-2 py-2 md:px-0 md:py-0">
                      {data?.length > 0 &&
                        data?.map((ele, idx) => {
                          if (ele?.name) {
                            return (
                              <>
                                <div
                                  className="hidden md:flex items-center space-x-2"
                                  key={idx}
                                >
                                  <input
                                    id={convertToServiceString(ele?.name)}
                                    name={ele?.name}
                                    type="checkbox"
                                    className="inputCheck accent-black"
                                    value={selectedService?.includes(ele?.name)}
                                    checked={selectedService?.includes(
                                      ele?.name
                                    )}
                                    onChange={(e) => handleCheckboxChange(e)}
                                  />
                                  <label
                                    htmlFor={convertToServiceString(ele?.name)}
                                    className="sub-header-labels"
                                  >
                                    {ele?.name}
                                  </label>
                                </div>
                                <Button
                                  name={ele?.name}
                                  label={ele?.name}
                                  variant={
                                    selectedService?.includes(ele?.name)
                                      ? "default"
                                      : "outline"
                                  }
                                  onClick={handleCheckboxChange}
                                  className="block md:hidden m-1"
                                />
                              </>
                            );
                          }
                        })}
                    </div>

                    <div className="w-full md:w-fit px-2 py-2 md:p-0 bg-[#9E9E9E] md:bg-transparent flex justify-end items-center gap-2">
                      {type?.length > 0 && (
                        <StMateButton onClick={handleReset}>
                          <MdDoNotDisturb size="24px" /> Reset
                        </StMateButton>
                      )}
                      <StMateButton
                        onClick={() =>
                          !handleDeepestLevel() &&
                          !submitLoading &&
                          getSystemPrompt()
                        }
                        bg="#0C97ED"
                        style={{
                          cursor: handleDeepestLevel()
                            ? "not-allowed"
                            : "pointer",
                        }}
                      >
                        {submitLoading ? (
                          <Loader color="#fff" size="24px" />
                        ) : (
                          <>
                            <PiArrowCircleRightBold size="24px" />
                          </>
                        )}
                        Submit
                      </StMateButton>
                    </div>
                  </div>
                ) : (
                  <div className="w-full h-[40px] flex justify-center items-center">
                    <Loader color="#426286" size={24} />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </TestDiv>
      {headerData?.data &&
        !loading &&
        selectedService?.length > 0 &&
        filterService &&
        filterService?.map((ele, index) => (
          <div
            key={ele?.id}
            // className={`mb-2 ${index == 0 ? "pt-[93px]" : "pt-[0px]"}`}
            style={{
              marginBottom: "8px",
              paddingTop: index == 0 ? divHeight + "px" : 0,
            }}
          >
            <ExpendCloseBtn
              key={ele?.id}
              text={selectedService[index]}
              onClick={() => handleExpendFun(selectedService[index])}
              changeIcon={newdata[selectedService[index]]?.isExpend}
            />
            {/* {newdata[selectedService[index]]?.isExpend && ( */}
            <div
              className={newdata[selectedService[index]]?.isExpend ? "open" : "close"}
            >
              <IssuesComp
                key={ele?.id}
                type={selectedService[index]}
                parentId={ele?.id}
              />
            </div>
            {/* )} */}
          </div>
        ))}
    </SubHeaderStyle>
  );
};

export default SubHeader;

export const StMateButton = styled.button`
  width: fit-content;
  color: #fff;
  padding: 8px 10px;
  border-radius: 14px;
  font-size: 14px;
  font-weight: 700;
  line-height: 14.52px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ bg }) => bg || "#000"};
  gap: 8px;
`;

export const TestDiv = styled.div`
  width: 100%;
  height: ${({ height }) => height};
  position: sticky;
  top: 0;

  .inputCheck {
    @media (max-width: 767px) {
      padding: 0px;
    }
  }
`;

const ExpendCloseBtn = ({ onClick, changeIcon, text }) => {
  return (
    <div
      onClick={onClick}
      className={`h-[52px] bg-[#e5e7eb] px-4 flex justify-start items-center gap-2 cursor-pointer rounded-[8px] mx-3 md:mx-6`}
    >
      <span className="font-bold">{text}</span>
      {changeIcon ? <FaChevronDown /> : <FaChevronRight />}
    </div>
  );
};

const SubHeaderStyle = styled.div`
  .open {
    /* max-height: 300vh; */
    max-height: fit-content;
    opacity: 1;
    /* transition: opacity 0.5s, max-height 0.2s; */
    transition: all 400ms cubic-bezier(0.99, 0.28, 0.05, 0.6),opacity 300ms;
    pointer-events: all;
    overflow: hidden;
  }
  
  .close {
    max-height: 0;
    opacity: 0;
    /* transition: opacity 0.5s, max-height 0.2s; */
    transition: all 400ms cubic-bezier(0.6, 0.05, 0.28, 0.99),opacity 1s;
    pointer-events: none;
    overflow: hidden;
  }

  /* client */
  /* .open {
    max-height: 300vh;
    max-height: fit-content;
    opacity: 1;
    transition: opacity 1s, max-height 3s;
    pointer-events: all;
    overflow: hidden;
  }

  .close {
    max-height: 0;
    opacity: 0;
    transition: opacity 1s, max-height 3s;
    pointer-events: none;
  } */
`;
