import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import PublicLayout from "layouts/PublicLayout";
import PrivateLayout from "layouts/PrivateLayout";
import { PublicPaths } from "./publicPaths";
import { PrivatePaths } from "./privatePaths";
import { useSelector } from "react-redux";

function PublicRoute({ isAuthenticated, currentActiveJob }) {
  if (isAuthenticated) {
    if (currentActiveJob) {
      return <Navigate to={`/v1/jobs/${currentActiveJob}`} />;
    }
    return <Navigate to="/v1/jobs" />;
  }
  return <Outlet />;
}

function PrivateRoute({ isAuthenticated }) {
  if (!isAuthenticated) return <Navigate to="/" />;
  return <Outlet />;
}

export default function AppRoutes() {
  const token = useSelector((state) => state?.shared
    ?.LoginSlice?.data?.token || null);
  const currentActiveJob = useSelector((state) => state?.tabSpecific?.STMate?.outsideJob || null);

  return (
    <Router>
      <Routes>
        {/* <Route path={"/set-new-password"} element={<SetNewPassword />} /> */}
        {/* <Route path={"/setup-password"} element={<SetupPassword />} /> */}
        <Route
          element={
            <PublicRoute
              isAuthenticated={token}
              currentActiveJob={currentActiveJob}
            />
          }
        >
          <Route path="/" element={<PublicLayout />}>
            {PublicPaths?.map((routes, index) => (
              <Route
                key={index}
                path={routes.path}
                element={routes.component}
              />
            ))}
          </Route>
        </Route>

        <Route element={<PrivateRoute isAuthenticated={token} />}>
          <Route path="/v1" element={<PrivateLayout />}>
            {PrivatePaths?.map((routes, index) => (
              <Route
                key={index}
                path={routes.path}
                element={routes.component}
              />
            ))}
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}
