import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Loader } from "./Loader";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import {
  findObjectOfKey,
  insertValueByKey,
  removeKeyValueFromObj,
  toggleStringInArray,
  uniqueArrayOfObjects,
} from "utils/Functions";
import {
  initalFromUpdate,
  updateExpendedItems,
} from "store/slices/StmateSlice";
import { useDispatch } from "react-redux";
import AddIssueWithButton from "./AddIssueWithButton";
import { ExpendCloseBtn } from "pages/stmate/IssuesComp";
import styled from "styled-components";

export const Button = ({
  label,
  variant = "default",
  onClick,
  className = "",
  name = "",
}) => {
  const buttonStyle =
    variant === "default"
      ? "border-[3px] border-[#000]"
      : "bg-white border border-[#8D939A]";

  return (
    <button
      name={name}
      className={`px-4 py-2 h-[52px] text-sm font-medium rounded-[8px] text-black ${buttonStyle} ${className}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export const Chevron = ({ direction, className }) => (
  <span className={`material-icons ${className}`}>
    {direction === "down" ? <FaChevronDown /> : <FaChevronRight />}
  </span>
);

export const IssueButton = ({ label, isActive, onClick }) => (
  <Button
    label={label}
    variant={isActive ? "default" : "outline"}
    onClick={onClick}
    className="m-1"
  >
    {label}
  </Button>
);

const SubIssuesComp = ({
  type,
  parentId,
  handleSubCategories,
  level = 1,
  expected,
}) => {
  const dispatch = useDispatch();
  const { clientsIssues, newdata, expendedItems } = useSelector(
    ({ tabSpecific }) => ({
      ...tabSpecific?.STMate,
    })
  );

  // const [isMainExpanded, setIsMainExpanded] = useState(expected);
  const [isLoaded, setIsLoaded] = useState(false);
  const [preFills, setPreFills] = useState(false);

  const issues = useMemo(
    () =>
      uniqueArrayOfObjects(
        clientsIssues?.filter((e) => e?.parent === parentId?.id) ?? [],
        "id"
      ),
    [clientsIssues, parentId?.id]
  );

  const childObjects = useMemo(
    () => findObjectOfKey(newdata ?? {}, type),
    [newdata, type]
  );

  const handleIssueClick = (issue) => {
    const foundKey = Object.keys(childObjects?.subItems ?? {}).find(
      (e) => e === issue.name
    );

    if (foundKey) {
      // const tempData = { ...newdata };
      // tempData[type] = removeKeyValueFromObj(childObjects, issue.name);
      // dispatch(initalFromUpdate(tempData));

      const result = insertValueByKey({ ...newdata }, type, issue.name);
      dispatch(initalFromUpdate(result));
    } else {
      const result = insertValueByKey({ ...newdata }, type, issue.name);
      dispatch(initalFromUpdate(result));
    }
    if (!expendedItems?.includes(issue?.name)) {
      dispatch(updateExpendedItems([...expendedItems, issue?.name]));
    }
  };

  const onRemoveKey = (key) => {
    const tempData = structuredClone(newdata);
    tempData[type] = removeKeyValueFromObj(tempData[type], key);
    dispatch(initalFromUpdate(tempData));
  };

  const handleChevronExpend = () => {
    // setIsMainExpanded((pre) => !pre);
    let r = toggleStringInArray(expendedItems ?? [], type);
    dispatch(updateExpendedItems(r));
  };

  useEffect(() => {
    if (level < 4 && !isLoaded) {
      const founds = clientsIssues?.filter((e) => e?.parent === parentId?.id);
      if (!founds?.length) {
        handleSubCategories(1, [parentId?.id], () => {
          //   onRemoveKey(parentId?.id);
          setIsLoaded(true);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsIssues?.length, parentId?.id]);

  if (level > 3) return null;
  return (
    <SubIssuesCompStyle>
      {issues?.length <= 0 && !isLoaded ? (
        <div className="w-full h-[50px] flex justify-center items-center">
          <Loader color="#426286" />
        </div>
      ) : (
        <>
          <div
            className={
              level === 1
                ? "closeP bg-gray-200 p-4 rounded-lg my-3"
                : "closeP border border-black py-4 px-4 rounded-lg m-4"
            }
          >
            <div
              className={`flex justify-between items-center`}
            >
              <button
                className="flex items-center text-sm font-normal gap-2"
                onClick={handleChevronExpend}
              >
                {type}
                <Chevron
                  direction={expected ? "down" : "up"}
                  className="mr-2"
                />
              </button>
            </div>
            {/* {expected && ( */}
            <div className={expected ? "openSub" : "closeSub"}>
              {/* <hr className="h-1 my-4 bg-black" /> */}
              <AddIssueWithButton type={type} level={level} />

              {issues?.length > 0 && (
                <ExpendCloseBtn
                  setExpendService={setPreFills}
                  expendService={preFills}
                  text={"View Pre-fills"}
                  isActive={preFills}
                />
              )}

              <div className={`${preFills ? "h-fit block" : "h-0 hidden"}`}>
                <div className="flex flex-wrap md:gap-2 md:mb-3">
                  {issues?.map((subIssue) => (
                    <IssueButton
                      key={subIssue?.name}
                      label={subIssue?.name}
                      isActive={
                        childObjects?.subItems?.[subIssue?.name]?.isActive
                      }
                      onClick={() => handleIssueClick(subIssue)}
                    />
                  ))}
                </div>

                <div className="w-full flex flex-col-reverse">
                  {Object.keys(childObjects?.subItems ?? {})
                    ?.filter(
                      (e) =>
                        e !== "label" &&
                        childObjects?.subItems?.[e]?.["isActive"]
                    )
                    ?.map((issue) => (
                      <SubIssuesComp
                        key={issue}
                        type={issue}
                        parentId={clientsIssues?.find((e) => e?.name === issue)}
                        handleSubCategories={handleSubCategories}
                        level={level + 1}
                        expected={expendedItems?.includes(issue)}
                      />
                    ))}
                </div>
              </div>

              {/* {issues?.length > 0 && (
                  <ExpendCloseBtn
                    setExpendService={setPreFills}
                    expendService={preFills}
                    text={"Hide Pre-fills"}
                    isActive={!preFills}
                  />
                )} */}
            </div>
            {/* )} */}
          </div>
        </>
      )}
    </SubIssuesCompStyle>
  );
};

export default SubIssuesComp;

const SubIssuesCompStyle = styled.div`
  /* .closeP {
    max-height: 300vh;
    transition: max-height 1.2s ease;
  } */
  .openSub {
    /* max-height: fit-content; */
    max-height: 300vh;
    opacity: 1;
    /* transition: opacity 0.5s ease, max-height 1s ease; */
    transition: all 0.4s cubic-bezier(0.99, 0.28, 0.05, 0.6);
    pointer-events: all;
  }

  .closeSub {
    max-height: 0;
    opacity: 0;
    /* transition: opacity 0.5s ease, max-height 0.2s ease; */
    transition: all 0.4s cubic-bezier(0.6, 0.05, 0.28, 0.99);
    pointer-events: none;
    overflow: hidden;
  }
`;
